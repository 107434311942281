import React from 'react';

function ProgramNameEditModal({setShowProgramNameEditModal, programName, updateProgramName}) {

    let programNameText = programName;
    function closeModal(){
        setShowProgramNameEditModal(false);
    }

    function setProgramName(text){
        programNameText = text;
    }

    return (
        <div className="modal" style={{display: "flex"}}>
            <div className="modal-content-centered">
                <span onClick={closeModal} className="close">&times;</span>
                <div className="modal-section">
                    <a style={{fontSize: "21px", fontWeight: "500"}}>Edit Name</a>
                    <div>
                        <a className={"modal-input-label"}>Name:</a>
                        <input className="exerciseName" style={{marginTop: "20px", marginBottom: "10px"}} size={49} defaultValue={programNameText} onChange={e => setProgramName(e.target.value)} />
                    </div>

                    <div style={{display: "flex", margin: "auto"}}>
                        <button className="normal-button-system" onClick={() => updateProgramName(programNameText)} style={{width: "160px", margin: "12px 12px 12px 0"}}>Update Name</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProgramNameEditModal;