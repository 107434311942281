import React from 'react';

function AddProgramModal({setShowAddProgramModalFlag, showNewProgramDialog, showImportProgramDialog}) {
    function closeModal(){
        setShowAddProgramModalFlag(false);
    }

    return (
        <div className="modal" style={{display: "flex"}}>
            <div className="modal-content-centered">
                <span onClick={closeModal} className="close">&times;</span>
                <div className="modal-section">
                    <a style={{fontSize: "21px", fontWeight: "500"}}>Add Program</a>
                    <div style={{display: "flex"}}>
                        <button className="normal-button-system" onClick={showNewProgramDialog} style={{width: "160px", margin: "12px 12px 12px 0"}}>New Program</button>
                        <button className="normal-button-system" onClick={showImportProgramDialog} style={{width: "160px", margin: "12px 0px 12px 12px"}}>Import File</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddProgramModal;