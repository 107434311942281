import React from 'react';

function DescriptionModal({setShowDescriptionModalFlag, descriptionModalText}) {
    function closeDescriptionModal(){
        setShowDescriptionModalFlag(false);
    }

    return (
        <div id="deleteExerciseModal" className="modal" style={{display: "flex"}}>
            <div className="modal-content-centered" style={{maxWidth: "70vw"}}>
                <span onClick={closeDescriptionModal} className="close">&times;</span>
                <div className="modal-section">
                    <a style={{fontSize: "21px", margin: "28px 10px", fontWeight: "500"}}>{descriptionModalText}</a>
                </div>
            </div>
        </div>
    );

}

export default DescriptionModal;