import React from 'react';

function GeneratedPhaseLinksModal({setShowGeneratedPhaseLinkModalFlag, generatedLinksStringData, selectedPhaseIndex}) {
    function closeModal(){
        setShowGeneratedPhaseLinkModalFlag(false);
    }

    function copyToClipBoard(){
        navigator.clipboard.writeText(generatedLinksStringData);
    }

    return (
        <div className="modal" style={{display: "flex"}}>
            <div className="modal-content-centered">
                <span onClick={closeModal} className="close">&times;</span>
                <div className="modal-section">
                    <a style={{fontSize: "21px", fontWeight: "500"}}>{"Phase " + (selectedPhaseIndex + 1) + " links:"}</a>
                    <textarea cols="77" rows="21" style={{marginTop: "20px", marginBottom: "20px"}} readOnly={true} value={generatedLinksStringData} />
                    <button className="normal-button-system" onClick={() => copyToClipBoard()} style={{width: "160px", margin: "auto", marginBottom: "12px"}}>Copy</button>
                </div>
            </div>
        </div>
    );
}

export default GeneratedPhaseLinksModal;