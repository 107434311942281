import React from 'react';

function DeleteExerciseModal({deleteModalMessage, deleteExercise, setShowDeleteExerciseModalFlag}) {
    function closeModal(){
        setShowDeleteExerciseModalFlag(false);
    }

    return (
        <div id="deleteExerciseModal" className="modal" style={{display: "flex"}}>
            <div className="modal-content-centered">
                <span onClick={closeModal} className="close">&times;</span>
                <div className="modal-section">
                    <span ><h3>{deleteModalMessage}</h3></span>
                    <button className="normal-button-system" onClick={deleteExercise} style={{width: "100px", margin: "auto", marginBottom: "12px"}}>Yes</button>
                </div>
            </div>
        </div>
    );
}

export default DeleteExerciseModal;