import React from 'react';

function PhaseTabCell({index, selected, setSelectedPhaseIndex}) {

    function phaseTapped(){
        setSelectedPhaseIndex(index);
    }

    return (
        <div className={"phase-tabs" + ( selected ? " selected" : "")} onClick={phaseTapped}>{"Phase " + (index + 1)}</div>
    );
}

export default PhaseTabCell;