import React from 'react';

function DayTabCell({index, selected, setSelectedDayIndex}) {

    function dayTapped(){
        setSelectedDayIndex(index);
    }

    return (
        <div className={"day-cell" + ( selected ? " selected" : "")} onClick={dayTapped}>{"Day " + (index + 1)}</div>
    );
}

export default DayTabCell;