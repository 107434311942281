import React from 'react';
import {globalExerciseFilterEquipmentsArray, removeItemAll} from "./shared/Constants";

function EquipmentFilterItem({index, selectedEquipments, setSelectedEquipments}) {

    function equipmentTapped(){
        if (index === 0){
            if (!selectedEquipments.includes(0)){
                setSelectedEquipments([0]);
            }
        } else {
            if (selectedEquipments.includes(index)){
                let oldArray = [...selectedEquipments];
                if (oldArray.length > 1){
                    oldArray = removeItemAll(oldArray, index);
                    setSelectedEquipments(removeItemAll(oldArray, index));
                } else {
                    setSelectedEquipments([0]);
                }
            } else {
                let oldArray = [...selectedEquipments];
                if (oldArray.includes(0)){
                    setSelectedEquipments([index]);
                } else {
                    oldArray.push(index);
                    console.log(oldArray);
                    setSelectedEquipments(oldArray);
                }
            }
        }
    }

    return (
        <div className={"exercise-search-filter-item-tabs" + ( selectedEquipments.includes(index) ? " filter-tab-selected" : "")} onClick={equipmentTapped}>
            {globalExerciseFilterEquipmentsArray[index]}
        </div>
    );
}

export default EquipmentFilterItem;