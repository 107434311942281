import React from 'react';
import {globalProgramTypeNormal, globalProgramTypeTwoWeekSame} from "./shared/Constants";

function WeekTabCell({index, programType, selected, setSelectedWeekIndex}) {
    function weekTapped(){
        setSelectedWeekIndex(index);
    }

    function getWeekTitle(){
        // console.log("weekType: ", programType);
        if (programType === globalProgramTypeNormal){
            return "Week " + (index+1);
        } else if (programType === globalProgramTypeTwoWeekSame) {
            if (index === 0){
                return "Week 1 & 2";
            } else if (index === 1) {
                return "Week 3 & 4";
            }
        }
        return "";
    }

    return (
        <div className={"week-tabs" + ( selected ? " selected" : "")} onClick={weekTapped}>{getWeekTitle()}</div>
    );
}

export default WeekTabCell;