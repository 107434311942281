import React from 'react';
import {globalExerciseFilterMGArray, removeItemAll} from "./shared/Constants";

function MuscleGroupFilterItem({index, selectedMuscleGroups, setSelectedMuscleGroups}) {

    function muscleGroupTapped(){
        if (index === 0){
            if (!selectedMuscleGroups.includes(0)){
                setSelectedMuscleGroups([0]);
            }
        } else {
            if (selectedMuscleGroups.includes(index)){
                let oldArray = [...selectedMuscleGroups];
                if (oldArray.length > 1){
                    oldArray = removeItemAll(oldArray, index);
                    setSelectedMuscleGroups(removeItemAll(oldArray, index));
                } else {
                    setSelectedMuscleGroups([0]);
                }
            } else {
                let oldArray = [...selectedMuscleGroups];
                if (oldArray.includes(0)){
                    setSelectedMuscleGroups([index]);
                } else {
                    oldArray.push(index);
                    console.log(oldArray);
                    setSelectedMuscleGroups(oldArray);
                }
            }
        }
    }

    return (
        <div className={"exercise-search-filter-item-tabs" + ( selectedMuscleGroups.includes(index) ? " filter-tab-selected" : "")} onClick={muscleGroupTapped}>
            {globalExerciseFilterMGArray[index]}
        </div>
    );
}

export default MuscleGroupFilterItem;