import React from 'react';

function AssignProgramToClientModal({setShowAssignProgramToClientModalFlag, clientEmail, setClientEmail, scanAndSendProgramToClient}) {

    function closeModal(){
        setShowAssignProgramToClientModalFlag(false);
    }

    return (
        <div className="modal" style={{display: "flex"}}>
            <div className="modal-content-centered">
                <span onClick={closeModal} className="close">&times;</span>
                <div className="modal-section">
                    <a style={{fontSize: "21px", fontWeight: "500"}}>Enter client email:</a>
                    <input className="exerciseName" style={{marginTop: "20px", marginBottom: "20px"}} size={49} value={clientEmail} onChange={e => setClientEmail(e.target.value)} />
                    <button className="normal-button-system" onClick={scanAndSendProgramToClient} style={{width: "140px", margin: "auto", marginBottom: "12px"}}>Send</button>
                </div>
            </div>
        </div>
    );
}

export default AssignProgramToClientModal;