import React, {useState} from 'react';
import {globalProgramTypeNormal, globalProgramTypeTwoWeekSame} from "../shared/Constants";

function NewProgramModal({ setShowNewProgramModalFlag, addNewProgram }) {

    const [programName, setProgramName] = useState("");
    const [programDescription, setProgramDescription] = useState("");
    const [programType, setProgramType] = useState(globalProgramTypeTwoWeekSame);

    function closeModal(){
        setShowNewProgramModalFlag(false);
    }

    const progTypeLabels = {
        "0": "All weeks are different",
        "1": "Week 1 & 2 are same"
    }

    return (
        <div className="modal" style={{display: "flex"}}>
            <div className="modal-content-centered">
                <span onClick={closeModal} className="close">&times;</span>
                <div className="modal-section">
                    <a style={{fontSize: "21px", fontWeight: "500"}}>Add New Program</a>
                    <div>
                        <a className={"modal-input-label"}>Name:</a>
                        <input className="exerciseName" style={{marginTop: "20px", marginBottom: "10px"}} size={49} value={programName} onChange={e => setProgramName(e.target.value)} />
                    </div>
                    <div>
                        <a className={"modal-input-label"}>Description:</a>
                        <input className="exerciseName" style={{margin:"10px 0"}} size={44} value={programDescription} onChange={e => setProgramDescription(e.target.value)} />
                    </div>
                    <div>
                        <a className={"modal-input-label"}>Program Type:</a>
                        <select className="exerciseName" style={{margin:"10px 0"}} value={programType} onChange={e => setProgramType(e.target.value)}>
                            <option value="0">Type 0</option>
                            <option value="1">Type 1</option>
                        </select>
                        <a className={"modal-input-select-info-label"}>{progTypeLabels[programType]}</a>
                    </div>
                    <div style={{display: "flex", margin: "auto"}}>
                        <button className="normal-button-system" onClick={() => addNewProgram(programName, programDescription, programType)} style={{width: "160px", margin: "12px 12px 12px 0"}}>Add Program</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewProgramModal;