import React from 'react';

function ErrorModal({errorModalMessage, setShowErrorModalFlag}) {
    function closeModal(){
        setShowErrorModalFlag(false);
    }

    return (
        <div id="deleteExerciseModal" className="modal" style={{display: "flex"}}>
            <div className="modal-content-centered" style={{maxWidth: "70vw"}}>
                <span onClick={closeModal} className="close">&times;</span>
                <div className="modal-section">
                    <a style={{fontSize: "21px", margin: "28px 10px", fontWeight: "500"}}>{errorModalMessage}</a>
                    <button className="normal-button-system" onClick={closeModal} style={{width: "100px", margin: "auto", marginBottom: "12px"}}>OK</button>
                </div>
            </div>
        </div>
    );
}

export default ErrorModal;