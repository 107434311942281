import React from 'react';
import ExerciseClass from "./ExerciseClass";
import {globalWorkoutCoolDownType, globalWorkoutWarmUpType} from "./shared/Constants";

function ExerciseSection({sectionIndex, programJson, selectedPhaseIndex, selectedWeekIndex, selectedDayIndex, exerciseMap, setTargetSection, setTargetIndex, setProgramJson, addToUndoStack, showErrorDialog}) {

    const dayJSON = programJson.p[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex];
    const sectionJSON = programJson.p[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].w[sectionIndex];

    function sectionHasTitle(){
        if ((sectionIndex === 1) && isWarmUpPresent()){
            //"Exercise separator" after warmup
            return true;
        }

        if (sectionJSON.hasOwnProperty("n")){
            //name exits
            const theName = sectionJSON["n"].toUpperCase();
            if (theName === "SUPERSET" || theName === "COMPOUND SET") {
                return false;
            }
            return true;
        } else if (sectionJSON.hasOwnProperty("tc")){
            //title code exists
            return true;
        } else if (sectionJSON.hasOwnProperty("tp")){
            const thisTp = sectionJSON["tp"];
            if (thisTp === globalWorkoutWarmUpType || thisTp === globalWorkoutCoolDownType) {
                return true;
            }
        }
        return false;
    }

    function getHeaderTitle(){
        if (sectionJSON.hasOwnProperty("n")){
            //name exits
            const theName = sectionJSON["n"].toUpperCase();
            if (theName === "SUPERSET" || theName === "COMPOUND SET") {
                if ((sectionIndex === 1) && isWarmUpPresent()){
                    //"Exercise separator" after warmup
                    return "Exercises".toUpperCase();
                }
            }
            return theName;
        } else if (sectionJSON.hasOwnProperty("tc")){
            //title code exists
            return getTitleFor(sectionJSON["tc"]);
        } else if (sectionJSON.hasOwnProperty("tp")){
            const thisTp = sectionJSON["tp"];
            if (thisTp === globalWorkoutWarmUpType) {
                return "Dynamic Warm Up Stretching".toUpperCase();
            } else if (thisTp === globalWorkoutCoolDownType) {
                return "Static Cool Down Stretching".toUpperCase();
            } else if ((sectionIndex === 1) && isWarmUpPresent()){
                //"Exercise separator" after warmup
                return "Exercises".toUpperCase();
            }
        } else if ((sectionIndex === 1) && isWarmUpPresent()){
            //"Exercise separator" after warmup
            return "Exercises".toUpperCase();
        }
        return "";
    }

    function isWarmUpPresent(){
        if (dayJSON.w[0].hasOwnProperty("tp")){
            const thisTp = dayJSON.w[0]["tp"];
            if (thisTp === globalWorkoutWarmUpType){
                return true;
            }
        }
        return false;
    }

    function getTitleFor(titleCode){
        switch (titleCode) {
            case 1:
                return "";
            case 3:
                return "CIRCUIT";
            case 4:
                const minutes = sectionJSON["t"];
                return "Steady State Cardio of Choice: " + minutes + " mins".toUpperCase();
            case 5:
                const mins = sectionJSON["t"];
                return "Cardio of Choice: " + mins + " mins".toUpperCase();
            default:
                return "";
        }
    }

    function getHeaderDiv(){
        if (sectionHasTitle()){
            return(
                <div className="exercise-header">
                    {getHeaderTitle()}
                </div>
            )
        }
    }

    function getExerciseSectionRows(){
        try {
            return <>
                {exerciseMap.size && sectionJSON.e.map((exerciseJSON, index) => <ExerciseClass exercise={exerciseMap.get(exerciseJSON["i"])} exerciseJSON={exerciseJSON} index={index} getHeaderDiv={getHeaderDiv} sectionIndex={sectionIndex} setTargetSection={setTargetSection} setTargetIndex={setTargetIndex} key={index} programJson={programJson} setProgramJson={setProgramJson} exerciseMap={exerciseMap} selectedPhaseIndex={selectedPhaseIndex} selectedWeekIndex={selectedWeekIndex} selectedDayIndex={selectedDayIndex} addToUndoStack={addToUndoStack} />)}
            </>
        } catch (e) {
            const message = "This workout seems corrupt: \n\n" +
                "ExerciseSection error at sectionIndex " + sectionIndex + " at Phase " + (selectedPhaseIndex + 1) + " Week Index " + (selectedWeekIndex) + " Day " + (selectedDayIndex + 1) + "\n\n" +
                "Please contact support and send this message.";
            alert(message);
            // showErrorDialog(message);
            console.log("ExerciseSection error at sectionIndex " + sectionIndex + " at Phase " + (selectedPhaseIndex + 1) + " Week Index " + (selectedWeekIndex) + " Day " + (selectedDayIndex + 1));
            console.log(e);
            return <></>
        }
    }

    return (
        <>
            {getExerciseSectionRows()}
        </>
    );
}

export default ExerciseSection;