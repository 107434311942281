export const globalWorkoutNormalType = 1;
export const globalWorkoutCircuitType = 2;
export const globalWorkoutSteadyStateCardio = 3;
export const globalTypeWorkoutCompoundSet = 4;
export const globalTypeWorkoutSuperSet = 5;
export const globalWorkoutCustomCardioRepeat = 6; //repeats exercises till total time
export const globalWorkoutWarmUpType = 7;
export const globalWorkoutCoolDownType = 8;

//Stretching
export const globalUpperBody = "Upper Body";
export const globalLowerBody = "Lower Body";
export const globalLowImpactCardio = "Low Impact Cardio";

//MuscleGroups
export const globalChest = "Chest";
export const globalBack = "Back";
export const globalTraps = "Traps";
export const globalShoulders = "Shoulders";
export const globalTriceps = "Triceps";
export const globalBiceps = "Biceps";
export const globalForearms = "Forearms";
export const globalLegs = "Legs";
export const globalCalves = "Calves";
export const globalGlutes_Hips = "Glutes/Hips";
export const globalLowerAbs = "Lower Abs";
export const globalMiddleAbs = "Middle Abs";
export const globalUpperAbs = "Upper Abs";
export const globalObliques = "Obliques";
export const globalCardio = "Cardio";

export const globalExerciseFilterMGArray = ["All", "Warm Ups", globalChest,globalBack,globalTraps,globalShoulders,globalTriceps,globalBiceps,globalForearms,globalLegs,globalCalves,globalGlutes_Hips,globalLowerAbs,globalMiddleAbs,globalUpperAbs,globalObliques,globalCardio, "Cool Downs"];
export const globalExerciseFilterEquipmentsArray = ["All", "Bodyweight","Dumbbell","Barbell","Resistance Band","Kettlebell","Cable","Medicine Ball","Machine"];

export const globalExerciseTypeWarmUp = 1;
export const globalExerciseTypeCoolDown = 2;


export const globalNewProgramID = "0";

export const globalBranchLiveKey = "key_live_ffC9wjTzzHauEL6XcJuoubfpyyaEW9uV";

export const globalProgramTypeNormal = "0";
export const globalProgramTypeTwoWeekSame = "1";

export const globalTimeExercise = 1;
export const globalSetsExercise = 2;
export const globalCircuitExercise = 3; //Time based
export const globalCompoundSetExercise = 4;
export const globalSuperSetExercise = 5;
export const globalTimeSetExercise = 6; //Time Exercise but with more than one set
export const globalCircuitExerciseRepsBased = 7;

export function removeItemAll(arr, value) {
    let i = 0;
    while (i < arr.length) {
        if (arr[i] === value) {
            arr.splice(i, 1);
        } else {
            ++i;
        }
    }
    return arr;
}

// export const globalProgramTypeNormalEmptyJson = {p:
//         [//3 phases:
//             {ph:[//4 weeks:
//                     {wk:[//5 days:
//                             {},{},{},{},{}
//                         ]},
//                     {wk:[{},{},{},{},{}]},
//                     {wk:[{},{},{},{},{}]},
//                     {wk:[{},{},{},{},{}]}
//                 ]},
//             {ph:[
//                     {wk:[{},{},{},{},{}]},
//                     {wk:[{},{},{},{},{}]},
//                     {wk:[{},{},{},{},{}]},
//                     {wk:[{},{},{},{},{}]}
//                 ]},
//             {ph:[
//                     {wk:[{},{},{},{},{}]},
//                     {wk:[{},{},{},{},{}]},
//                     {wk:[{},{},{},{},{}]},
//                     {wk:[{},{},{},{},{}]}
//                 ]}
//         ]
// };

export const globalProgramTypeNormalEmptyJson = {p:
        [//3 phases:
            {ph:[//4 weeks:
                    {wk:[//5 days:
                            {},{},{},{},{}
                        ]}
                ]}
        ]
};

export const globalProgramTypeTwoWeekSameEmptyJson = {p:
        [//3 phases:
            {ph:[//2 weeks:
                    {wk:[//5 days:
                            {},{},{},{},{}
                        ]},
                    {wk:[{},{},{},{},{}]}
                ]},
            {ph:[
                    {wk:[{},{},{},{},{}]},
                    {wk:[{},{},{},{},{}]}
                ]},
            {ph:[
                    {wk:[{},{},{},{},{}]},
                    {wk:[{},{},{},{},{}]}
                ]}
        ]
};