import React from 'react';
import {globalExerciseTypeCoolDown, globalExerciseTypeWarmUp} from "../shared/Constants";

function ExerciseSearchResultCell({exercise, setSelectedExerciseID, exerciseIndex}) {

    function onExerciseSelected(){
        setSelectedExerciseID(exercise["ID"]);
    }

    function getRightSubText(){
        return (
            <div className="rightSubText">
                {(exercise["PREMIUM"] === "TRUE") && <div className="premiumText">PREMIUM</div>}
                {(exercise["TYPE"] === globalExerciseTypeWarmUp) && <div className="warmUpText">WARM UP</div>}
                {(exercise["TYPE"] === globalExerciseTypeCoolDown) && <div className="coolDownText">COOL DOWN</div>}
            </div>
        )
    }

    function getExerciseCellRightDiv(){
        return (
            <>
                <div className={"exerciseCellRightDiv"}>
                    <div className="flexGrowOne exerciseNameDescDiv">
                        <div className="exerciseName">{exercise["NAME"]}</div>
                        <div className="muscleGroupEquipment">
                            {exercise["MUSCLE_GROUP"]} | {exercise["EQUIPMENT"]} {exercise["EXTRA_EQUIPMENT"] && "| " + exercise["EXTRA_EQUIPMENT"]}
                        </div>
                    </div>
                    {getRightSubText()}
                </div>
            </>
        )
    }

    function getExerciseLeftDiv(){
        return (
            <div className="exerciseLeftDiv">
                <img className="exerciseImage" src= {"/thumbs/" + exercise["ID"] + ".jpg"} loading="lazy" alt={exercise["NAME"] + " exercise thumbnail"}/>
            </div>
        )
    }

    function getSectionHeaderTitle(){
        const exerciseType = exercise["TYPE"];

        if (exerciseType === globalExerciseTypeWarmUp){
            return "Dynamic Warm Up Stretching";
        } else if (exerciseType === globalExerciseTypeCoolDown ){
            return "Static Cool Down Stretching";
        } else {
            return exercise["MUSCLE_GROUP"];
        }
    }

    return (
        <>
            {exerciseIndex === 0 &&
                <div className={"exercise-header exercise-search-cell-header"}>{getSectionHeaderTitle()}</div>
            }

            <div className={"exercise-search-cell-div"} onClick={onExerciseSelected}>
                <div className="exercise-cell">
                    {getExerciseLeftDiv()}
                    {getExerciseCellRightDiv()}
                </div>
                <div className="exercise-separater"></div>
            </div>
        </>
    );
}

export default ExerciseSearchResultCell;