import React from 'react';
import ExerciseSearchResultCell from "./ExerciseSearchResultCell";

function ExerciseSearchResultSection({exercises, setSelectedExerciseID}) {
    return (
        <>
            {exercises.map((exercise, index) =>
                // <>


                    <ExerciseSearchResultCell exercise={exercise}
                                              setSelectedExerciseID={setSelectedExerciseID}
                                              exerciseIndex={index} key={index}/>


            )}
        </>
    );
}

export default ExerciseSearchResultSection;