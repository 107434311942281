import React from 'react';

function ProgramDescriptionEditModal({setShowProgramDescriptionEditModal, programDescription, updateProgramDescription}) {
    let programDescriptionText = programDescription;
    function closeModal(){
        setShowProgramDescriptionEditModal(false);
    }

    function setProgramDescription(text){
        programDescriptionText = text;
    }

    return (
        <div className="modal" style={{display: "flex"}}>
            <div className="modal-content-centered">
                <span onClick={closeModal} className="close">&times;</span>
                <div className="modal-section">
                    <a style={{fontSize: "21px", fontWeight: "500"}}>Edit Description</a>
                    <div>
                        <textarea cols="77" rows="14" style={{marginTop: "20px", marginBottom: "20px"}} defaultValue={programDescriptionText} onChange={e => setProgramDescription(e.target.value)} />
                    </div>

                    <div style={{display: "flex", margin: "auto"}}>
                        <button className="normal-button-system" onClick={() => updateProgramDescription(programDescriptionText)} style={{width: "210px", margin: "12px 12px 12px 0"}}>Update Description</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProgramDescriptionEditModal;