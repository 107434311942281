import React from 'react';

function ImportDayModal({setShowImportDayModalFlag, importDayLink, setImportDayLink, startImportDay}) {

    function closeModal(){
        setShowImportDayModalFlag(false);
    }

    return (
        <div className="modal" style={{display: "flex"}}>
            <div className="modal-content-centered">
                <span onClick={closeModal} className="close">&times;</span>
                <div className="modal-section">
                    <a style={{fontSize: "21px", fontWeight: "500"}}>Enter day link:</a>
                    <input className="exerciseName" style={{marginTop: "20px", marginBottom: "20px"}} size={49} value={importDayLink} onChange={e => setImportDayLink(e.target.value)} />
                    <button className="normal-button-system" onClick={startImportDay} style={{width: "140px", margin: "auto", marginBottom: "12px"}}>Import Day</button>
                </div>
            </div>
        </div>
    );
}

export default ImportDayModal;