import React from 'react';
import ExerciseQuickSearchResultCell from "./ExerciseQuickSearchResultCell";

function ExerciseQuickSearchResultSection({exercises, setSelectedExerciseID}) {
    return (
        <>
            {exercises.map((exercise, index) =>
                // <>


                <ExerciseQuickSearchResultCell exercise={exercise}
                                          setSelectedExerciseID={setSelectedExerciseID}
                                          exerciseIndex={index} key={index} showHeader={true}/>


            )}
        </>
    );
}

export default ExerciseQuickSearchResultSection;