import {useState} from "react";
import Spinner from "../components/shared/Spinner";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

function UserLogIn(){

    // State variables
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [showSignUp, setShowSignUp] = useState(false);
    const [showForgotPassword, setShowForgotPassword] = useState(false);

    function handleSubmit(e){
        e.preventDefault();
        error!=="" && setError("")
        doUserLogIn();
    }

    const doUserLogIn = async function () {
        setLoading(true);
        console.log("Logging in");

        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                console.log("logged in");
                console.log(user);
                // setLoading(true);
                // alert(user.displayName + " logged in successfully!");
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode);
                console.log(errorMessage);

                // Error can be caused by wrong parameters or lack of Internet connection
                setError(`${error.message}`);
                setLoading(false);
            });


    }

    function signUpClicked(){
        error!=="" && setError("")
        setShowSignUp(true);
    }

    function forgotPasswordClicked(){
        error!=="" && setError("")
        setShowForgotPassword(true);
    }


    return (
        <section className="login-section">

            { loading ? <Spinner/> :
                <>
                    <div className="login-form-outer-div">
                        <img className="login-logo" alt="AnabolicAliens logo" src="https://trainer.anabolicaliens.com/Anabolic_Logo_140x@2x.avif" width="200"/>
                        <form onSubmit={handleSubmit} method="post">
                            <div className="container">
                                <label><b>Email</b></label>
                                <input value={email} onChange={(event) => setEmail(event.target.value.toLowerCase())}
                                       type="email" placeholder="Enter Email" name="uname" required/>

                                <label><b>Password</b></label>
                                <input value={password} onChange={(event) => setPassword(event.target.value)}
                                       type="password" placeholder="Enter Password" name="psw" required/>

                                <button type="submit" className="login-button">Login</button>
                            </div>
                        </form>
                        <span onClick={forgotPasswordClicked} className="forgot-password-link">Forgot password?</span>
                        { error!=="" && <div className="form-fail">{error}</div>}
                    </div>

                    <div style={{ marginTop: "15px" }} className="login-form-outer-div container">
                        <span>Don't have an account? &nbsp;</span><span className="forgot-password-link" onClick={signUpClicked}>Sign Up</span>
                    </div>

                </>
            }

        </section>
    )
}

export default UserLogIn;