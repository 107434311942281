import React from 'react';
import spinner from "../assets/spinner.gif";

function ModalSpinner({message}) {
    return (
        <div className={"modal"} style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
            <img src={spinner} alt="loading" style={{ width: '50px', display: 'block' }}/>
            <a style={{color: "white", margin: "20px", fontWeight: "600"}}>{message}</a>
        </div>
    );
}

export default ModalSpinner;