import React from 'react';

function AddExerciseModal({addExercise, addSuperSetCompoundSet, addCircuitSection, addWarmUpSection, addCoolDownSection, shouldShowAddWarmUpButton, shouldShowAddCoolDownButton, setShowAddExerciseModalFlag}) {

    function hideAddExerciseModal(){
        setShowAddExerciseModalFlag(false);
    }

    return (
        <div id="addExerciseModal" className="modal" style={{display: "flex"}}>
            <div className="modal-content-centered">
                <span onClick={hideAddExerciseModal} className="close">&times;</span>
                <div className="modal-section">
                    <span ><h3>Select Exercise Type</h3></span>
                    <button className="normal-button-system-light" onClick={addExercise}>Add Exercise</button>
                    <button className="normal-button-system-light" onClick={addSuperSetCompoundSet}>Superset / Compound Set</button>
                    <button className="normal-button-system-light" onClick={addCircuitSection}>Create Circuit</button>
                    { shouldShowAddWarmUpButton() && <button className="normal-button-system-light" onClick={addWarmUpSection}>Warm Up</button> }
                    { shouldShowAddCoolDownButton() && <button className="normal-button-system-light" onClick={addCoolDownSection}>Cool Down</button> }
                </div>
            </div>
        </div>
    );
}

export default AddExerciseModal;