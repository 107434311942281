import React, {useEffect, useRef, useState} from 'react';
import {addDoc, collection, deleteDoc, doc, getDocs, serverTimestamp, setDoc} from "firebase/firestore";
import {deleteObject, getDownloadURL, getStorage, ref, uploadBytes} from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";

import Exercises from "../components/db/EXERCISE.json" //import the EXERCISE.json file as a Exercises JSON array
import ExerciseSection from "../components/ExerciseSection";
import ExerciseSearch from "../components/ExerciseSearch";
import DayTabCell from "../components/DayTabCell";
import PhaseTabCell from "../components/PhaseTabCell";
import WeekTabCell from "../components/WeekTabCell";
import branch from 'branch-sdk';
import ModalSpinner from "../components/shared/ModalSpinner";
import {
    globalBranchLiveKey,
    globalCalves,
    globalCardio, globalCircuitExercise, globalCircuitExerciseRepsBased, globalCompoundSetExercise,
    globalLegs,
    globalLowerAbs,
    globalMiddleAbs,
    globalNewProgramID,
    globalProgramTypeNormal,
    globalProgramTypeNormalEmptyJson,
    globalProgramTypeTwoWeekSame,
    globalProgramTypeTwoWeekSameEmptyJson,
    globalSetsExercise, globalSuperSetExercise,
    globalTimeExercise, globalTimeSetExercise,
    globalTypeWorkoutCompoundSet,
    globalTypeWorkoutSuperSet,
    globalUpperAbs,
    globalWorkoutCircuitType,
    globalWorkoutCoolDownType,
    globalWorkoutNormalType,
    globalWorkoutWarmUpType
} from "../components/shared/Constants";
import ProgramsRow from "../components/MainDashboard/ProgramsRow";
import ImportDayModal from "../components/MainDashboard/ImportDayModal";
import addIcon from "../components/assets/add_box_FILL1_wght400_GRAD0_opsz24.svg";
import AddProgramModal from "../components/MainDashboard/AddProgramModal";
import NewProgramModal from "../components/MainDashboard/NewProgramModal";
import ErrorModal from "../components/shared/ErrorModal";

import URI from 'urijs';
import ProgramLinkImporterModal from "../components/MainDashboard/ProgramLinkImporterModal";
import GeneratedPhaseLinksModal from "../components/MainDashboard/GeneratedPhaseLinksModal";
import OkModal from "../components/shared/OkModal";
import DescriptionModal from "../components/MainDashboard/DescriptionModal";
import ProgramNameEditModal from "../components/ProgramNameEditModal";
import ProgramDescriptionEditModal from "../components/ProgramDescriptionEditModal";
import undoIcon from "../components/assets/undo_FILL0_wght400_GRAD0_opsz24.svg"
import redoIcon from "../components/assets/redo_FILL0_wght400_GRAD0_opsz24.svg"
import AssignProgramToClientModal from "../components/MainDashboard/AssignProgramToClientModal";

function Dashboard({db, userID, user, functions}) {

    const [exerciseMap, setExerciseMap] = useState(new Map());
    const [programJson, setProgramJson] = useState({});
    const [targetSection, setTargetSection] = useState(0);
    const [targetIndex, setTargetIndex] = useState(0);
    const [selectedPhaseIndex, setSelectedPhaseIndex] = useState(0);
    const [selectedWeekIndex, setSelectedWeekIndex] = useState(0);
    const [selectedDayIndex, setSelectedDayIndex] = useState(0);
    const [selectedMainTab, setSelectedMainTab] = useState(0);
    const [selectedProgramID, setSelectedProgramID] = useState(globalNewProgramID);
    const [loading, setLoading] = useState(false);
    const [programs, setPrograms] = useState([]);
    const [selectedProgramInfoJson, setSelectedProgramInfoJson] = useState({});
    // const [selectedProgramIndex, setSelectedProgramIndex] = useState(0);
    const [showImportDayModalFlag, setShowImportDayModalFlag] = useState(false);
    const [importDayLink, setImportDayLink] = useState("");
    const [showAddProgramModalFlag, setShowAddProgramModalFlag] = useState(false);
    const [showNewProgramModalFlag, setShowNewProgramModalFlag] = useState(false);
    const [showImportProgramModalFlag, setShowImportProgramModalFlag] = useState(false);
    const [showErrorModalFlag, setShowErrorModalFlag] = useState(false);
    const [errorModalMessage, setErrorModalMessage] = useState("");
    const [programIsLoading, setProgramIsLoading] = useState(false);
    const [loaderMessage, setLoaderMessage] = useState("");
    const [showProgramLinkImporterModalFlag, setShowProgramLinkImporterModalFlag] = useState(false);
    const [programLinkImporterInputData, setProgramLinkImporterInputData] = useState("");
    const [showGeneratedPhaseLinkModalFLag, setShowGeneratedPhaseLinkModalFlag] = useState(false);
    const [generatedLinksStringData, setGeneratedLinksStringData] = useState("");
    const [okModalMessage, setOkModalMessage] = useState("");
    const [showOkModalFlag, setShowOkModalFlag] = useState("");
    const [totalTimeString, setTotalTimeString] = useState("");
    const [showDescriptionModalFlag, setShowDescriptionModalFlag] = useState(false);
    const [descriptionModalText, setDescriptionModalText] = useState("");
    const [showProgramNameEditModal, setShowProgramNameEditModal] = useState(false);
    const [showProgramDescriptionEditModal, setShowProgramDescriptionEditModal] = useState(false);
    const [nameSearchText, setNameSearchText] = useState("");
    const [descriptionSearchText, setDescriptionSearchText] = useState("");
    const [filteredPrograms, setFilteredPrograms] = useState([]);
    const [sortID, setSortID] = useState(0);
    const [undoStack, setUndoStack] = useState([]);
    const [redoStack, setRedoStack] = useState([]);
    const [pauseAddingToUndoStack, setPauseAddingToUndoStack] = useState(false);
    const [clientEmail, setClientEmail] = useState("");
    const [showAssignProgramToClientModalFlag, setShowAssignProgramToClientModalFlag] = useState(false);

    const sortID_Name_Ascending = 0;
    const sortID_Name_Descending = 1;
    const sortID_Description_Ascending = 2;
    const sortID_Description_Descending = 3;
    const sortID_UpdatedOn_Ascending = 4;
    const sortID_UpdatedOn_Descending = 5;
    const sortID_ID_Ascending = 6;
    const sortID_ID_Descending = 7;

    // Get a reference to the storage service, which is used to create references in your storage bucket
    const storage = getStorage();

    useEffect(() => {
        loadPrograms().then(r => {
            console.log("loadPrograms done");
        });
        loadExerciseMap();
    }, []);

    useEffect(() => {
        if (programs.length > 0){
            displaySearchedPrograms();
        }
    }, [programs]);

    useEffect(() => {
        loadDayTitle();
    }, [exerciseMap]);

    useEffect(() => {
        loadDayTitle();
        calculateEstimatedWorkoutTime();
    }, [selectedDayIndex, selectedWeekIndex, selectedPhaseIndex]);

    useEffect(() => {
        console.log("programJson updated: ", programJson);
        if (exerciseMap.size && (selectedProgramID !== globalNewProgramID)){
            setDayTitle();
            calculateEstimatedWorkoutTime();
        }
    }, [programJson]);

    useEffect(() => {
        if (programs.length > 0){
            displaySearchedPrograms();
        }
    }, [nameSearchText, descriptionSearchText, sortID]);

    // useEffect(() => {
    //     if (selectedProgramID !== globalNewProgramID){
    //         downloadFileFromServer();
    //     }
    // }, [selectedProgramID]);

    async function loadPrograms() {
        showLoader();
        let tempPrograms = [];
        const querySnapshot = await getDocs(collection(db, "trainers/"+userID+"/programs"));
        await querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            let thisData = doc.data();
            thisData["programID"] = doc.id;
            tempPrograms.push(thisData);
            console.log(doc.id, " => ", doc.data());
        });
        // console.log("temp programs:");
        // tempPrograms.sort((a,b) => (a["name"]).localeCompare(b["name"]));
        // console.log(tempPrograms);
        setPrograms(tempPrograms);
        // console.log("ended");
        console.log("programs:");
        console.log(programs);
        hideLoader();
    }

    function displaySearchedPrograms(){
        let nameQueryWords = nameSearchText.toLowerCase().split(' ').filter(queryWord => queryWord.length > 0);
        let descriptionQueryWords = descriptionSearchText.toLowerCase().split(' ').filter(queryWord => queryWord.length > 0);

        setFilteredPrograms([...programs].filter(row =>
                    nameQueryWords.every(queryWord =>
                        row.name.toLowerCase().split(' ').includes(queryWord)
                        ||  row.name.toLowerCase().includes(queryWord)
                    )
                    && descriptionQueryWords.every(queryWord =>
                        row.desc.toLowerCase().split(' ').includes(queryWord)
                        ||  row.desc.toLowerCase().includes(queryWord)
                    )
            )
            .sort((a,b) => (sortID === sortID_Name_Ascending ? (a.name).localeCompare(b.name) :
                    sortID === sortID_Name_Descending ? (b.name).localeCompare(a.name) :
                        sortID === sortID_Description_Ascending ? (a.desc).localeCompare(b.desc) :
                            sortID === sortID_Description_Descending ? (b.desc).localeCompare(a.desc) :
                                sortID === sortID_UpdatedOn_Ascending ? (a.updated)-(b.updated) :
                                    sortID === sortID_UpdatedOn_Descending ? (b.updated)-(a.updated) :
                                        sortID === sortID_ID_Ascending ? (a.programID).localeCompare(b.programID) :
                                            sortID === sortID_ID_Descending && (b.programID).localeCompare(a.programID)
            ))
        );
    }

    function setSortIDFor(targetSortID){
        switch (targetSortID) {
            case sortID_Name_Ascending:
                (sortID === sortID_Name_Ascending) ? setSortID(sortID_Name_Descending) : setSortID(sortID_Name_Ascending)
                break;
            case sortID_Description_Ascending:
                (sortID === sortID_Description_Ascending) ? setSortID(sortID_Description_Descending) : setSortID(sortID_Description_Ascending)
                break;
            case sortID_UpdatedOn_Ascending:
                (sortID === sortID_UpdatedOn_Ascending) ? setSortID(sortID_UpdatedOn_Descending) : setSortID(sortID_UpdatedOn_Ascending)
                break;
            case sortID_ID_Ascending:
                (sortID === sortID_ID_Ascending) ? setSortID(sortID_ID_Descending) : setSortID(sortID_ID_Ascending)
                break;
            default:
                break;
        }
    }

    function loadExerciseMap(){
        // console.log("inside loadExerciseMap");
        //convert EXERCISE json to a id : exercise map
        let tempExerciseMap = new Map();
        for (const exercise of Exercises) {
            tempExerciseMap.set(exercise.ID, exercise);
        }
        // console.log("inside loadExerciseMap before setting");
        setExerciseMap(tempExerciseMap);
        // console.log("inside loadExerciseMap after setting");
    }

    function addFirstExercise(){
        let originalProgramJson = deepCopy({...programJson});
        let originalPArray = JSON.parse(JSON.stringify([...programJson.p]));
        let newWArray = [];
        let nextSectionJSON = {
            tp: 1,
            e: [
                {
                    rt: 60,
                    t: 45,
                    s: 2,
                    i: 1
                }
            ]
        };
        newWArray.splice( 1,0, nextSectionJSON);
        console.log("newWArray:");
        console.log(newWArray);
        originalPArray[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].w = newWArray;
        console.log("setting ProgramJson from addFirstExercise");
        addToUndoStack(originalProgramJson);
        setProgramJson({...programJson, p: originalPArray});
    }

    function getAddExerciseButton(){
        return (
            <div style={{display: "flex", justifyContent: "center"}}>
                <button className="normal-button-system" onClick={addFirstExercise} style={{width: "340px", margin: "5px"}}>Add Exercise</button>
            </div>
        )
    }

    function generateLink(){
        showLoader();
        initBranch().then(r=> {
            const linkData = {
                campaign: 'GenerateLink',
                channel: 'TrainerWebApp',
                feature: 'GenerateSingleLink',
                data: {
                    'deeplinked': "1",
                    'deepto': "workoutBuilder",
                    "jsonString": JSON.stringify(programJson.p[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex]),
                    '$uri_redirect_mode': "1",
                    '$og_title': 'Exerprise Workout',
                    '$og_description': 'Workout built using Exerprise!'
                }
            };

            branch.link(linkData, async function (err, link) {
                await navigator.clipboard.writeText(link);
                hideLoader();
                console.log(link);
            });
        });
    }

    async function saveProgram() {
        showLoader();

        try {
            // if (selectedProgramID === globalNewProgramID){
            //     //create program
            //     const docRef = await addDoc(collection(db, "trainers/"+userID+"/programs"), {
            //         name: programs[selectedProgramIndex].name,
            //         desc: programs[selectedProgramIndex].desc,
            //         created: serverTimestamp(),
            //         updated: serverTimestamp()
            //     });
            //     console.log("Program " + docRef.id + " created successfully");
            //     setSelectedProgramID(docRef.id);
            //     await uploadFileToServer();
            // } else {
                //update program
                await setDoc(doc(db, "trainers/"+userID+"/programs", selectedProgramID), {
                        name: selectedProgramInfoJson?.name,
                        desc: selectedProgramInfoJson?.desc,
                        updated: serverTimestamp()
                    }, { merge: true }
                );
                console.log("Program " + selectedProgramID + ": " + selectedProgramInfoJson.name + " updated successfully");
                await uploadFileToServer(programJson, selectedProgramID);
            // }
            hideLoader();
        } catch (e) {
            console.error("Error adding document: ", e);
            showErrorDialog("Error" + e);
            hideLoader();
        }
    }

    async function uploadFileToServer(json, programID) {
        // Child references can also take paths delimited by '/'
        const fileRef = ref(storage, "trainers/" + userID + "/programs/" + programID);

        const file = new Blob([JSON.stringify(json)], {type: 'application/octet-stream'});

        await uploadBytes(fileRef, file).then((snapshot) => {
            console.log('Uploaded file successfully!');
        });
    }

    function loadSelectedProgram(programInfoJson){
        setProgramIsLoading(true);
        setPauseAddingToUndoStack(true);
        // setSelectedProgramIndex(index);
        setSelectedProgramInfoJson(programInfoJson);
        setSelectedProgramID(programInfoJson.programID);
        downloadFileFromServer(programInfoJson.programID);
        if (programInfoJson.hasOwnProperty("clientEmail")){
            setClientEmail(programInfoJson.clientEmail);
        }
    }

    function downloadFileFromServer(programID) {
        const fileRef = ref(storage, "trainers/" + userID + "/programs/" + programID);
        showLoader();
        // getBlob
        getDownloadURL(fileRef)
            .then((url) => {
                // This can be downloaded directly:
                const xhr = new XMLHttpRequest();
                // xhr.responseType = 'blob';
                xhr.responseType = 'text';
                xhr.onload = (event) => {
                    // const blob = xhr.response;
                    const jsonString = xhr.responseText;
                    console.log("file downloaded successfully for " + programID);
                    const thisProgramJSON = JSON.parse(jsonString);
                    console.log("thisProgramJSON:");
                    console.log(thisProgramJSON);
                    console.log("setting ProgramJson from downloadFileFromServer");
                    setProgramJson(thisProgramJSON);
                    hideLoader();
                    setProgramIsLoading(false);
                    setPauseAddingToUndoStack(false);
                };
                xhr.open('GET', url);
                xhr.send();
            })
            .catch((error) => {
                // Handle any errors
                showErrorDialog(error);
                hideLoader();
                setProgramIsLoading(false);
            });
    }

    function addToUndoStack(originalProgramJson){
        const newJSON = [...undoStack];
        newJSON.push(deepCopy(originalProgramJson));
        setUndoStack(newJSON);
    }

    function addToRedoStack() {
        const thisProgramJson = {...programJson};
        const newJSON = [...redoStack];
        newJSON.push(deepCopy(thisProgramJson));
        setRedoStack(newJSON);
    }

    function loadFromUndoStack(){
        if (undoStack.length > 0){
            addToRedoStack();
            let undoArray = [...undoStack];
            const popped = deepCopy(undoArray.pop());
            setUndoStack(undoArray);
            setProgramJson(popped);
        }
    }

    function loadFromRedoStack(){
        if (redoStack.length > 0){
            let originalProgramJson = deepCopy({...programJson});
            addToUndoStack(originalProgramJson);
            let redoArray = [...redoStack];
            const popped = deepCopy(redoArray.pop());
            setRedoStack(redoArray);
            setProgramJson(popped);
        }
    }

    function exportProgram(){
        showLoader();
        saveFileLocally(programJson, 'Program', 'application/octet-stream');
        hideLoader();
    }

    function loadDayTitle(){
        if (selectedProgramID === globalNewProgramID){
            return;
        }
        if (exerciseMap.size && !dayHasTitle()){
            console.log("loadDayTitle: dayDoesNotHaveTitle");
            setDayTitle();
        } else {
            if (exerciseMap.size){
                console.log("loadDayTitle: dayHasTitle");
            } else {
                console.log("loadDayTitle: exerciseMap is empty");
            }
        }
    }

    function dayHasTitle(){
        if (selectedProgramID !== globalNewProgramID){
            return programJson.p[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].hasOwnProperty("tl");
        }
        return false;
    }

    function getDayTitle(){
        return programJson.p && programJson.p[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].tl;
    }

    function setDayTitle(){
        try {
            let newTitle = generateDayTitle(programJson.p[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex]);
            let newDayJSON = programJson.p[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex];
            if (newDayJSON["tl"] !== newTitle) {
                let originalPArray = JSON.parse(JSON.stringify([...programJson.p]));
                newDayJSON["tl"] = generateDayTitle(programJson.p[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex]);
                originalPArray[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex] = newDayJSON;
                console.log("setting ProgramJson from setDayTitle");
                setProgramJson({...programJson, p: originalPArray});
                // setProgramJson(({p: originalPArray}));
            }
        } catch (e) {
            console.log("error in setDayTitle: " + e);
        }
    }

    function generateDayTitle(dayJSON){
        // const dayJSON = programJson.p[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex];
        // console.log("dayJSON:");
        // console.log(dayJSON);
        const muscleGroups = [];
        if (dayJSON.w?.length > 0){
            for (let i = 0; i < dayJSON.w.length; i++){
                const thisSection = dayJSON.w[i];
                // console.log("thisSection");
                // console.log(thisSection);
                const thisSectionType = thisSection["tp"];
                //Skip stretching
                if (!(thisSectionType === globalWorkoutWarmUpType || thisSectionType === globalWorkoutCoolDownType)){
                    for (let j = 0; j < thisSection.e.length; j++){
                        const thisExerciseID = thisSection.e[j]["i"];
                        // console.log("thisExerciseID:");
                        // console.log(thisExerciseID);
                        let thisExercise = exerciseMap.get(thisExerciseID);
                        // console.log("exercise:")
                        // console.log(thisExercise);
                        let thisMuscleGroup = thisExercise["MUSCLE_GROUP"];

                        //Lower abs, middle abs & upper abs as just Abs
                        if ([globalUpperAbs, globalMiddleAbs, globalLowerAbs].includes(thisMuscleGroup)){
                            thisMuscleGroup = "Abs";
                        }
                        //Treat calves as legs
                        if([globalCalves].includes(thisMuscleGroup)){
                            thisMuscleGroup = globalLegs;
                        }
                        if([globalCardio].includes(thisMuscleGroup)){
                            thisMuscleGroup = globalCardio;
                        }
                        if (!muscleGroups.includes(thisMuscleGroup)){
                            muscleGroups.push(thisMuscleGroup);
                        }
                    }
                }
            }
        }

        let dayTitleText = "";
        if (muscleGroups.length > 0){
            for (let i= 0; i < muscleGroups.length; i++){
                if (i === 0){
                    dayTitleText = muscleGroups[i];
                } else {
                    dayTitleText = dayTitleText + "/" + muscleGroups[i];
                }
            }
        }
        // console.log("dayTitleText:");
        // console.log(dayTitleText);
        return dayTitleText;
    }

    function deepCopy(json){
        return JSON.parse(JSON.stringify(json));
    }

    function showOkDialog(message){
        setOkModalMessage(message);
        setShowOkModalFlag(true);
    }

    function scanAndProgramForErrors(){
        showLoader();
        setLoaderMessage("Scanning program for errors...");

        let originalProgramJson = deepCopy({...programJson});

        let errors = 0;
        let emptyDays = 0;
        let originalPArray = deepCopy([...programJson.p]);
        for (let phaseIndex = 0; phaseIndex < originalPArray.length; phaseIndex++) {
            for (let weekIndex = 0; weekIndex < originalPArray[phaseIndex].ph.length; weekIndex ++){
                for (let dayIndex = 0; dayIndex < originalPArray[phaseIndex].ph[weekIndex].wk.length; dayIndex++) {

                    if (!originalPArray[phaseIndex].ph[weekIndex].wk[dayIndex].hasOwnProperty("w")){
                        emptyDays = emptyDays + 1;
                    } else {
                        let thisDayWJson = originalPArray[phaseIndex].ph[weekIndex].wk[dayIndex]["w"];

                        let newDayWJson = [];
                        for (let sectionIndex = 0; sectionIndex < thisDayWJson.length; sectionIndex ++ ){
                            let thisSectionJson = thisDayWJson[sectionIndex];
                            if (thisSectionJson.hasOwnProperty("e")){
                                if (!(thisSectionJson["e"].length > 0)){
                                    console.log("Phase " + (phaseIndex+1) + " " + getWeekText(phaseIndex, weekIndex)  + " Day " + (dayIndex + 1) + " Section " + (sectionIndex + 1) + " has e array but array is empty");
                                    errors = errors + 1;
                                    //exclude this section
                                } else {
                                    //include this section
                                    newDayWJson.push(deepCopy(thisSectionJson));
                                }
                            } else {
                                console.log("Phase " + (phaseIndex+1) + " " + getWeekText(phaseIndex, weekIndex)  + " Day " + (dayIndex + 1) + " Section " + (sectionIndex + 1) + " doesn't have e array");
                                errors = errors + 1;
                                //exclude this section
                            }
                        }
                        originalPArray[phaseIndex].ph[weekIndex].wk[dayIndex]["w"] = newDayWJson;
                    }

                }
            }
        }

        addToUndoStack(originalProgramJson);
        setProgramJson({...programJson, p: originalPArray});

        if (errors > 0){
            let message = (errors > 1) ? errors + " errors" : "1 error";
            if (emptyDays > 0){
                message = message + " and " + ((emptyDays > 1) ? emptyDays + " empty days" : "1 empty day");
            }
            message = message + " found and fixed.";
            showOkDialog(message);
        } else {
            let message = "No errors";
            if (emptyDays > 0){
                message = message + " and " + ((emptyDays > 1) ? emptyDays + " empty days" : "1 empty day");
            }
            message = message + " found in this program.";
            showOkDialog(message);
        }

        hideLoader();
    }

    function secondsToHHMMSSTimestamp(timeInSeconds){
        let finalString;
        let hours = Math.floor(timeInSeconds / 3600);
        let mins = Math.floor((timeInSeconds % 3600) / 60);
        let secs = Math.floor((timeInSeconds % 3600) % 60);

        if (hours > 0) {
            if (mins > 0) {
                finalString = ((hours>1) ? hours + " HRS" :  hours + " HR") + ((mins>1) ? " " + mins + " MINS" : " 1 MIN");
            } else {
                finalString = ((hours>1) ? hours + " HRS" : hours + " HR");
            }
        } else {
            if (secs > 0) {
                if (mins > 0) {
                    finalString = ((mins>1) ? mins + " MINS" : mins + " MIN") + ((secs>1) ? " " + secs + " SECS" : " 1 SEC");
                } else {
                    finalString = ((secs>1) ? secs + " SECS" : "1 SEC");
                }
            } else {
                finalString = ((mins>1) ? mins + " MINS" : mins + " MIN");
            }
        }

        return finalString;

    }

    function calculateEstimatedWorkoutTime(){
        try {
            let totalEstimatedTimeInSeconds = getTotalEstimatedWorkoutTimeInSeconds();
            let totalTimeText = ("Expected Workout Time : " + secondsToHHMMSSTimestamp(totalEstimatedTimeInSeconds)).toUpperCase();
            setTotalTimeString(totalTimeText);
        } catch(e){
            setTotalTimeString("");
            console.log("calculateEstimatedWorkoutTime error:");
            console.log(e);
        }
    }

    function getTotalEstimatedWorkoutTimeInSeconds(){
        let exerciseJSON = generateExerciseJSON();

        // console.log("exerciseJSON generated:");
        // console.log(exerciseJSON);

        let totalTime = 0;
        let timeInSecondsForOneSet = 30;

        for (let i = 0; i < exerciseJSON.length; i++) {
            let exercise = exerciseJSON[i];
            let thisExerciseTime = 0;

            // console.log("exercise: ");
            // console.log(exercise);
            // console.log("exercise[et]: " + exercise["et"]);
            switch (exercise["et"]) {

                case globalTimeExercise:
                case globalCircuitExercise:
                case globalTimeSetExercise:
                    thisExerciseTime = exercise["t"] + exercise["rt"];
                    // console.log("thisExerciseTime: " + thisExerciseTime);
                    break;

                case globalSetsExercise:
                case globalCompoundSetExercise:
                case globalSuperSetExercise:
                case globalCircuitExerciseRepsBased:
                    thisExerciseTime = timeInSecondsForOneSet + exercise["rt"];
                    // console.log("thisExerciseTime2: " + thisExerciseTime);
                    break;

                default:
                    // console.log("thisExerciseTime default: " + thisExerciseTime);
                    thisExerciseTime = 0;
            }

            totalTime = totalTime + thisExerciseTime;
            // console.log("totalTime: " + totalTime);
        }

        return totalTime;
    }

    function generateExerciseJSON(){
        let exerciseJSON = [];
        const dayJSON = deepCopy(programJson.p[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex]);
        if (dayJSON.w?.length > 0) {
            for (let i = 0; i < dayJSON.w.length; i++) {
                const thisSection = dayJSON.w[i];

                if (thisSection.hasOwnProperty("e") && thisSection.hasOwnProperty("tp")) {
                    const thisSectionType = thisSection["tp"];

                    switch (thisSectionType) {
                        case globalWorkoutNormalType:
                        case globalWorkoutWarmUpType:
                        case globalWorkoutCoolDownType:
                            //Normal
                            // console.log("section " + (i+1) + " is Normal type");
                            exerciseJSON.push(...getJSONArrayForNormalSection(thisSection));
                            break;
                        case globalWorkoutCircuitType:
                            //Circuit
                            // console.log("section " + (i+1) + " is Circuit type");
                            exerciseJSON.push(...getJSONArrayForCircuit(thisSection));
                            break;
                        case globalTypeWorkoutCompoundSet:
                            //Compound Set
                            // console.log("section " + (i+1) + " is Compound Set type");
                            exerciseJSON.push(...getJSONArrayForCompoundSet(thisSection, false));
                            break;
                        case globalTypeWorkoutSuperSet:
                            //Superset
                            // console.log("section " + (i+1) + " is Super Set type");
                            exerciseJSON.push(...getJSONArrayForCompoundSet(thisSection, true));
                            break;
                        default:
                            console.log("default");
                            break;
                    }
                }

            }
        }
        return exerciseJSON;
    }

    function getJSONArrayForNormalSection(sectionJson){
        let jsonArray = [];

        for (let j = 0; j < sectionJson["e"].length; j++){
            const thisExercise = sectionJson["e"][j];

            if (thisExercise.hasOwnProperty("r")){
                //Sets n reps style
                jsonArray.push(...getSetsArrayForExercise(thisExercise));
            } else if (thisExercise.hasOwnProperty("s") && thisExercise.hasOwnProperty("t")){
                //Time based but with sets
                jsonArray.push(...getTimeSetsArrayForExercise(thisExercise));
            } else {
                //Time based
                jsonArray.push(...getTimeExerciseJSONForExercise(thisExercise));
            }
        }

        return jsonArray;
    }

    function getSetsArrayForExercise(exerciseJson) {
        let setsArray = [];

        const sets = exerciseJson["s"];

        for (let i=0; i < sets; i++ ){
            const thisJSON = {
                "i":exerciseJson["i"],
                "r":exerciseJson["r"].toString(),
                "s":exerciseJson["s"],
                "rt":exerciseJson["rt"],
                "cs":(i+1),
                "et":globalSetsExercise
            }
            setsArray.push(thisJSON);
        }

        return setsArray;
    }

    function getTimeExerciseJSONForExercise(exerciseJson) {

        let thisRestTime = 0;

        if (exerciseJson.hasOwnProperty("rt")) {
            thisRestTime = exerciseJson["rt"];
        }

        let thisJSON = {
            "i": exerciseJson["i"],
            "t": exerciseJson["t"],
            "rt": thisRestTime,
            "et": globalTimeExercise
        }

        return thisJSON;
    }

    function getTimeSetsArrayForExercise(exerciseJson) {
        let setsArray = [];

        const sets = exerciseJson["s"];

        let thisRestTime = 0;

        if (exerciseJson.hasOwnProperty("rt")) {
            thisRestTime = exerciseJson["rt"];
        }

        for (let i=0; i < sets; i++ ){
            const thisJSON = {
                "i":exerciseJson["i"],
                "t":exerciseJson["t"],
                "s":exerciseJson["s"],
                "cs":i+1,
                "rt":thisRestTime,
                "et":globalTimeSetExercise
            }

            setsArray.push(thisJSON)
        }

        return setsArray
    }

    // this is for builder type circuit only:
    function getJSONArrayForCircuit(sectionJSON){
        let jsonArray = [];

//        t active time
//        rt rest time (between exercises)
//        rd rounds (number of rounds needed to call it one set) 1 set = 2 rounds
//        cs Circuit Sets = Number of sets of this circuit
//        crt = Rest time between circuit sets //seconds

        //Rest time between exercises should be rt
        //But last exercise of the set should have crt and not rt

        let numberOfCircuitSets = sectionJSON["cs"];
        // console.log("numberOfCircuitSets: " + numberOfCircuitSets);
        for (let i= 0; i < numberOfCircuitSets; i++ ){
            //circuits
            let numberOfRoundsForOneCircuit = sectionJSON["rd"];
            // console.log("numberOfRoundsForOneCircuit: " + numberOfRoundsForOneCircuit);
            for (let j = 0; j < numberOfRoundsForOneCircuit; j++) {
                //rounds
                let numberOfExercises = sectionJSON["e"].length;
                // console.log("numberOfExercises: " + numberOfExercises);
                for (let k = 0; k < numberOfExercises; k++) {
                    //exercises

                    //                i - image
                    //                t - time
                    //                rt - rest time
                    //                rd - rounds
                    //                crd - current round
                    //                cs - circuit sets
                    //                ccs - current circuit set
                    //                et - exercise type

                    let exercise = sectionJSON["e"][k];

                    let thisRestTime = 0;

                    if ((k === (numberOfExercises - 1)) && (j === (numberOfRoundsForOneCircuit - 1))) {
                        //Last Exercise of the set : should have crt and not rt
                        // console.log("Last Exercise of the set : should have crt and not rt");
                        thisRestTime = sectionJSON["crt"];
                    } else {
                        //Rest time between exercises should be rt
                        // console.log("Rest time between exercises should be rt");
                        thisRestTime = exercise["rt"];
                    }

                    if (exercise.hasOwnProperty("r")) {
                        //sets n reps style
                        let thisJSON = {
                            "i":exercise["i"],
                            "r":exercise["r"],
                            "rt":thisRestTime,
                            "rd":sectionJSON["rd"],
                            "crd":j+1,
                            "cs":sectionJSON["cs"],
                            "ccs":i+1,
                            "et":globalCircuitExerciseRepsBased
                        }

                        jsonArray.push(thisJSON);
                    } else {
                        //time interval type
                        let thisJSON = {
                            "i":exercise["i"],
                            "t":exercise["t"],
                            "rt":thisRestTime,
                            "rd":sectionJSON["rd"],
                            "crd":j+1,
                            "cs":sectionJSON["cs"],
                            "ccs":i+1,
                            "et":globalCircuitExercise
                        }
                        jsonArray.push(thisJSON);
                    }
                }
            }
        }
        // console.log("jsonArray: ");
        // console.log(jsonArray);
        return jsonArray
    }

    function getJSONArrayForCompoundSet(sectionJSON, superSet){
        let jsonArray = [];

        let sets = sectionJSON["s"];

        for (let i = 0; i < sets; i++) {
            for (let j = 0; j < sectionJSON["e"].length; j++) {

                // no rest time for firstExercise
                let thisRestTime = 0;

                if (j === 1) {
                    //rest time between sets
                    thisRestTime = sectionJSON["rt"];
                }

                let exercise = sectionJSON["e"][j];

                let exerciseType = globalCompoundSetExercise;

                if (superSet) {
                    exerciseType = globalSuperSetExercise;
                }

                if (sectionJSON.hasOwnProperty("r")) {
                    let thisJSON = {
                        "i":exercise["i"],
                        "r":sectionJSON["r"],
                        "s":sets,
                        "rt":thisRestTime,
                        "cs":i+1,
                        "et":exerciseType
                    }

                    jsonArray.push(thisJSON);
                } else {
                    let thisJSON = {
                        "i":exercise["i"],
                        "t":sectionJSON["t"],
                        "s":sets,
                        "rt":thisRestTime,
                        "cs":i+1,
                        "et":exerciseType
                    }

                    jsonArray.push(thisJSON);
                }

            }
        }

        return jsonArray
    }

    function saveFileLocally(content, fileName, contentType) {
        const a = document.createElement("a");
        const file = new Blob([JSON.stringify(content)], {type: contentType});
        a.href = URL.createObjectURL(file);
        a.download = fileName;
        a.click();
        URL.revokeObjectURL(a.href);
    }

    const inputFile = useRef(null);
    function openProgramFile() {
        // `current` points to the mounted file input element
        inputFile.current.click();
    };

    function loadLocalFile(e) {
        const file = e.target.files[0];
        console.log(file);
        const reader = new FileReader();

        reader.onload = function() {
            console.log(reader.result);
        };

        reader.readAsText(file);
    }

    function showImportDayDialog(){
        setImportDayLink("");
        setShowImportDayModalFlag(true);
    }

    function startImportDay(){
        showLoader();
        setShowImportDayModalFlag(false);
        // initBranch().then(r => {
            const targetURL = "https://api2.branch.io/v1/url?url=" + importDayLink + "&branch_key=" + globalBranchLiveKey;
            fetch(targetURL)
                .then((response) => response.json())
                .then((json) => {
                    console.log(json);
                    setDayJSONFromLink(json.data.jsonString);
                    hideLoader();
                })
                .catch(error => {
                    console.log(error);
                    hideLoader();
                    setShowImportDayModalFlag(true);
                });
        // });
    }

    function setDayJSONFromLink( jsonString ){
        let originalProgramJson = deepCopy({...programJson});
        let originalPArray = JSON.parse(JSON.stringify([...programJson.p]));
        originalPArray[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex] = JSON.parse(jsonString);
        console.log("setting ProgramJson from setDayJSONFromLink");
        addToUndoStack(originalProgramJson);
        setProgramJson({...programJson, p: originalPArray});
    }

    async function initBranch(){
        const options = {no_journeys: true};
        await branch.init(globalBranchLiveKey, options, function(err, data) {
            console.log(err, data);
        });
    }

    function showAddProgramDialog(){
        setShowAddProgramModalFlag(true);
    }

    function hideAddProgramDialog(){
        setShowAddProgramModalFlag(false);
    }

    function showNewProgramDialog(){
        hideAddProgramDialog();
        setShowNewProgramModalFlag(true);
    }

    function hideNewProgramDialog(){
        setShowNewProgramModalFlag(false);
    }

    function showImportProgramDialog(){
        hideAddProgramDialog();
        setShowImportProgramModalFlag(true);
    }

    async function addNewProgram(programName, programDescription, programType) {
        if (programName === "") {
            showErrorDialog("Program name cannot be empty");
        } else {
            createNewProgram(programName, programDescription, programType, getBlankJSON(programType)).then(() => {
                console.log("createNewProgram completed");
                hideLoader();
            });
        }
    }

    function getBlankJSON(programType) {
        let newProgramJSON = {};
        if (programType === globalProgramTypeNormal) {
            newProgramJSON = globalProgramTypeNormalEmptyJson;
        } else if (programType === globalProgramTypeTwoWeekSame) {
            newProgramJSON = globalProgramTypeTwoWeekSameEmptyJson;
        }
        newProgramJSON["tp"] = programType;
        return newProgramJSON;
    }

    async function createNewProgram(programName, programDescription, programType, newProgramJson) {
        showLoader();
        const newInfoData = {
            name: programName,
            desc: programDescription,
            type: programType,
            created: serverTimestamp(),
            updated: serverTimestamp()
        };

        try {
            const docRef = await addDoc(collection(db, "trainers/" + userID + "/programs"), newInfoData);
            console.log("Program " + docRef.id + " created successfully");
            // const blankJSON = getBlankJSON(programType);
            // console.log("blank JSON:");
            // console.log(blankJSON);
            await uploadFileToServer(newProgramJson, docRef.id);
            hideNewProgramDialog();
            setSelectedProgramID(docRef.id);
            console.log("setting programJson from createNewProgram");
            setSelectedProgramInfoJson(newInfoData);
            setProgramJson(newProgramJson);
        } catch (e) {
            console.error("Error adding document: ", e);
            showErrorDialog("Error" + e);
            hideLoader();
        }
    }

    function showErrorDialog(message){
        setErrorModalMessage(message);
        setShowErrorModalFlag(true);
    }

    function goToHome(){
        setSelectedProgramID(globalNewProgramID);
        console.log("setting programJson from goToHome");
        const newProgramJSON = globalProgramTypeNormalEmptyJson;
        newProgramJSON["tp"] = 0;
        setSelectedDayIndex(0);
        setSelectedWeekIndex(0);
        setSelectedPhaseIndex(0);
        setProgramLinkImporterInputData("");
        setGeneratedLinksStringData("");
        setProgramJson(newProgramJSON);
        setTotalTimeString("");
        setUndoStack([]);
        setRedoStack([]);
        setClientEmail("");
        loadPrograms();
    }

    async function deleteProgram(programInfoJson) {
        showLoader();
        const targetProgID = programInfoJson.programID;
        await deleteDoc(doc(db, "trainers/" + userID + "/programs", targetProgID));
        const deleteRef = ref(storage, "trainers/" + userID + "/programs/" + targetProgID);

        // Delete the file
        deleteObject(deleteRef).then(() => {
            console.log("File deleted successfully for " + targetProgID);
            hideLoader();
            loadPrograms();
        }).catch((error) => {
            // Uh-oh, an error occurred!
            // console.log("error deleting file for " + targetProgID + " : " + error);
            showErrorDialog("error deleting file for " + targetProgID + " : " + error);
            hideLoader();
            loadPrograms();
        });
    }

    async function duplicateProgram(programInfo) {
        setProgramIsLoading(true);
        setPauseAddingToUndoStack(true);
        showLoader();
        downloadFileFromServerForDuplicating(programInfo);
    }

    function downloadFileFromServerForDuplicating(programInfo) {
        const fileRef = ref(storage, "trainers/" + userID + "/programs/" + programInfo.programID);
        showLoader();
        // getBlob
        getDownloadURL(fileRef)
            .then((url) => {
                // This can be downloaded directly:
                const xhr = new XMLHttpRequest();
                // xhr.responseType = 'blob';
                xhr.responseType = 'text';
                xhr.onload = (event) => {
                    // const blob = xhr.response;
                    const jsonString = xhr.responseText;
                    console.log("file downloaded successfully for " + programInfo.programID);
                    const thisProgramJSON = JSON.parse(jsonString);
                    console.log("thisProgramJSON:");
                    console.log(thisProgramJSON);
                    console.log("setting ProgramJson from downloadFileFromServer");
                    setProgramJson(thisProgramJSON);
                    hideLoader();
                    setProgramIsLoading(false);
                    setPauseAddingToUndoStack(false);
                    createNewDuplicateProgram(programInfo, thisProgramJSON);
                };
                xhr.open('GET', url);
                xhr.send();
            })
            .catch((error) => {
                // Handle any errors
                showErrorDialog(error);
                hideLoader();
                setProgramIsLoading(false);
            });
    }

    function createNewDuplicateProgram(programInfo, newJSON){
        let newProgramInfo = JSON.parse(JSON.stringify(programInfo));
        newProgramInfo.name = newProgramInfo.name + " Copy";
        console.log("programJson:");
        console.log(programJson);
        createNewProgram(newProgramInfo.name, newProgramInfo.desc, newProgramInfo.type, newJSON).then(() => {
            console.log("createNewProgram completed");
            hideLoader();
        });
    }

    function showLoader(){
        setLoading(true);
    }

    function hideLoader(){
        setLoading(false);
        setLoaderMessage("");
    }

    function showProgramLinkImporterDialog(){
        setProgramLinkImporterInputData("");
        setShowProgramLinkImporterModalFlag(true);
    }

    async function startProgramImportViaLinks() {
        setShowProgramLinkImporterModalFlag(false);
        showLoader();
        setLoaderMessage("Extracting urls...");

        let urls = [];

        await URI.withinString(programLinkImporterInputData, function (url) {
            // callback needs to return a string
            // feel free to URI(url).normalize().toString() or something
            urls.push(url);
        });

        console.log("parsed urls:", urls);


        let originalPArray = JSON.parse(JSON.stringify([...programJson.p]));
        for (let phaseIndex = 0; phaseIndex < originalPArray.length; phaseIndex++) {
            if (urls.length > 0) {
                for (let dayIndex = 0; dayIndex < 5; dayIndex++) {
                    if (urls.length > 0) {
                        setLoaderMessage("Fetching workout for Phase " + (phaseIndex + 1) + " " + getWeekText(phaseIndex, 0) + " Day " + (dayIndex + 1) + "...");
                        console.log("Fetching workout for Phase " + (phaseIndex + 1) + " " + getWeekText(phaseIndex, 0) + " Day " + (dayIndex + 1) + "...");
                        const jsonFromLink = await getJSONFromLink(urls[0]);
                        console.log("Fetched workout for Phase " + (phaseIndex + 1) + " " + getWeekText(phaseIndex, 0) + " Day " + (dayIndex + 1) + "...");
                        originalPArray[phaseIndex].ph[0].wk[dayIndex]["w"] = jsonFromLink.w;
                        urls.shift();
                    } else {
                        console.log("skipping Phase " + (phaseIndex + 1) + " " + getWeekText(phaseIndex, 0) + " Day " + (dayIndex + 1) + " as urls is empty");
                    }
                    if (urls.length > 0) {
                        setLoaderMessage("Fetching workout for Phase " + (phaseIndex + 1) + " " + getWeekText(phaseIndex, 1) + " Day " + (dayIndex + 1) + "...");
                        console.log("Fetching workout for Phase " + (phaseIndex + 1) + " " + getWeekText(phaseIndex, 1) + " Day " + (dayIndex + 1) + "...");
                        const jsonFromLink = await getJSONFromLink(urls[0]);
                        console.log("Fetched workout for Phase " + (phaseIndex + 1) + " " + getWeekText(phaseIndex, 1) + " Day " + (dayIndex + 1) + "...");
                        originalPArray[phaseIndex].ph[1].wk[dayIndex]["w"] = jsonFromLink.w;
                        urls.shift();
                    } else {
                        console.log("skipping Phase " + (phaseIndex + 1) + " " + getWeekText(phaseIndex, 1) + " Day " + (dayIndex + 1) + " as urls is empty");
                    }
                }

            } else {
                console.log("skipping phase " + (phaseIndex + 1) + " as urls is empty");
            }
        }

        console.log("originalPArray: ", [...programJson.p]);
        console.log("new originalPArray: ", originalPArray);
        setProgramJson({...programJson, p: originalPArray});
        hideLoader();

    }

    function wait(delay){
        return new Promise((resolve) => setTimeout(resolve, delay));
    }

    async function getJSONFromLink(link) {
        const targetURL = "https://api2.branch.io/v1/url?url=" + link + "&branch_key=" + globalBranchLiveKey;
        return fetch(targetURL)
                .then((response) => response.json())
                .then((json) => {
                    const thisJSON = JSON.parse(json.data.jsonString);
                    console.log(thisJSON);
                    return thisJSON;
                })
                .catch(error => {
                    console.log(error);
                    return wait(1000).then(async () => {
                        return getJSONFromLink(link);
                    });
                });
    }

    function getWeekText(phaseIndex, weekIndex){
        if (phaseIndex === 0){
            if (weekIndex === 0){
                return "Week 1&2";
            } else {
                return "Week 3&4";
            }
        } else if (phaseIndex === 1){
            if (weekIndex === 0){
                return "Week 5&6";
            } else {
                return "Week 7&8";
            }
        } else if (phaseIndex === 2){
            if (weekIndex === 0){
                return "Week 9&10";
            } else {
                return "Week 11&12";
            }
        }
    }

    async function generateSelectedPhaseLinks() {
        showLoader();

        initBranch().then(async r => {
            let stringData = "";

            let originalPArray = JSON.parse(JSON.stringify([...programJson.p]));
            for (let dayIndex = 0; dayIndex < 5; dayIndex++) {

                setLoaderMessage("Fetching workout for Phase " + (selectedPhaseIndex + 1) + " Week 1 & 2 Day " + (dayIndex + 1) + "...");
                console.log("Generating link for Phase " + (selectedPhaseIndex + 1) + " " + getWeekText(selectedPhaseIndex, 0) + " Day " + (dayIndex + 1) + "...");
                const linkFromJson = await asyncProcess(originalPArray[selectedPhaseIndex].ph[0].wk[dayIndex]);
                console.log("Generated link for Phase " + (selectedPhaseIndex + 1) + " " + getWeekText(selectedPhaseIndex, 0) + " Day " + (dayIndex + 1) + "...");
                stringData = stringData + "Day " + (dayIndex + 1) + " " + getWeekText(selectedPhaseIndex, 0) + ":\n" + linkFromJson + "\n\n";


                setLoaderMessage("Fetching workout for Phase " + (selectedPhaseIndex + 1) + " " + getWeekText(selectedPhaseIndex, 1) + " Day " + (dayIndex + 1) + "...");
                console.log("Generating link for Phase " + (selectedPhaseIndex + 1) + " " + getWeekText(selectedPhaseIndex, 1) + " Day " + (dayIndex + 1) + "...");
                const linkFromJson2 = await asyncProcess(originalPArray[selectedPhaseIndex].ph[1].wk[dayIndex]);
                console.log("Generated link for Phase " + (selectedPhaseIndex + 1) + " " + getWeekText(selectedPhaseIndex, 1) + " Day " + (dayIndex + 1) + "...");
                stringData = stringData + "Day " + (dayIndex + 1) + " " + getWeekText(selectedPhaseIndex, 1) + ":\n" + linkFromJson2 + "\n\n";

            }
            hideLoader();
            console.log("string data: ", stringData);
            setGeneratedLinksStringData(stringData);
            setShowGeneratedPhaseLinkModalFlag(true);
        });

    }

    function asyncProcess(json) {
        return new Promise((resolve, reject) => {
            const linkData = {
                campaign: 'GenerateLink',
                channel: 'TrainerWebApp',
                feature: 'GenerateSingleLink',
                data: {
                    'deeplinked': "1",
                    'deepto': "workoutBuilder",
                    "jsonString": JSON.stringify(json),
                    '$uri_redirect_mode': "1",
                    '$og_title': 'Exerprise Workout',
                    '$og_description': 'Workout built using Exerprise!'
                }
            };

            return branch.link(linkData, async function (err, link) {
                console.log("generated: ", link);
                return resolve(link);
            });
        })
    }

    function showProgramNameEditDialog(){
        setShowProgramNameEditModal(true);
    }

    function hideProgramNameEditDialog(){
        setShowProgramNameEditModal(false);
    }

    async function updateProgramName(name) {
        if (name === ""){
            showErrorDialog("Program name cannot be blank");
        } else {
            showLoader();
            try {
                //update program
                await setDoc(doc(db, "trainers/" + userID + "/programs", selectedProgramID), {
                        name: name,
                        updated: serverTimestamp()
                    }, {merge: true}
                );
                selectedProgramInfoJson.name = name;
                console.log("Name for Program " + selectedProgramID + ": " + selectedProgramInfoJson.name + " updated successfully");
                hideProgramNameEditDialog();
                hideLoader();
            } catch (e) {
                console.error("Error updating document: ", e);
                showErrorDialog("Error" + e);
                hideLoader();
            }
        }
    }

    function showProgramDescriptionEditDialog(){
        setShowProgramDescriptionEditModal(true);
    }

    function hideProgramDescriptionEditDialog(){
        setShowProgramDescriptionEditModal(false);
    }

    async function updateProgramDescription(newDescription) {
        console.log(newDescription);
        showLoader();
        try {
            //update program
            await setDoc(doc(db, "trainers/" + userID + "/programs", selectedProgramID), {
                    desc: newDescription,
                    updated: serverTimestamp()
                }, {merge: true}
            );
            selectedProgramInfoJson.desc = newDescription;
            console.log("Description for Program " + selectedProgramID + ": " + selectedProgramInfoJson.name + " updated successfully");
            hideProgramDescriptionEditDialog();
            hideLoader();
        } catch (e) {
            console.error("Error updating document: ", e);
            showErrorDialog("Error" + e);
            hideLoader();
        }
    }

    function clearCurrentDay(){
        let originalProgramJson = deepCopy({...programJson});
        let originalPArray = deepCopy([...programJson.p]);
        originalPArray[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex] = {};
        addToUndoStack(originalProgramJson);
        setProgramJson({...programJson, p: originalPArray});
    }

    function showAssignProgramToClientDialog(){
        // setClientEmail("");
        setShowAssignProgramToClientModalFlag(true);
    }

    async function scanAndSendProgramToClient() {

        if (clientEmail === "") {
            showErrorDialog("Client email cannot be blank");
        } else {
            let originalPArray = deepCopy([...programJson.p]);
            for (let weekIndex = 0; weekIndex < originalPArray[selectedPhaseIndex].ph.length; weekIndex++) {
                for (let dayIndex = 0; dayIndex < originalPArray[selectedPhaseIndex].ph[weekIndex].wk.length; dayIndex++) {
                    let thisDayJSON = originalPArray[selectedPhaseIndex].ph[weekIndex].wk[dayIndex];

                    let weekText = "Week " + (weekIndex + 1);
                    if (programJson["tp"] === globalProgramTypeTwoWeekSame) {
                        if (weekIndex === 0) {
                            weekText = "Week 1 & 2";
                        } else if (weekIndex === 1) {
                            weekText = "Week 3 & 4";
                        }
                    }

                    if (!thisDayJSON.hasOwnProperty("w")) {
                        showErrorDialog("Error: " + weekText + " Day " + (dayIndex + 1) + " is empty.");
                        return;
                    }
                    if (!thisDayJSON.hasOwnProperty("tl")) {
                        //title is empty
                        console.log("Title is empty for " + weekText + " Day " + (dayIndex + 1));
                        try {
                            let newTitle = generateDayTitle(thisDayJSON);
                            if (thisDayJSON["tl"] !== newTitle) {
                                let originalPArray = JSON.parse(JSON.stringify([...programJson.p]));
                                thisDayJSON["tl"] = newTitle;
                                originalPArray[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex] = thisDayJSON;
                                console.log("Setting title for " + weekText + " Day " + (dayIndex + 1));
                                // console.log("setting ProgramJson from setDayTitle");
                                // setProgramJson({...programJson, p: originalPArray});
                                // setProgramJson(({p: originalPArray}));
                            }
                        } catch (e) {
                            console.log("error in set tl: " + e);
                        }
                    }
                }
            }
            console.log("setting ProgramJson from scanAndSendProgramToClient");
            setProgramJson({...programJson, p: originalPArray});
            await assignProgramToClient(originalPArray);
        }

    }

    async function assignProgramToClient(originalPArray) {
        showLoader();
        //mohitsinghonweb@gmail.com
        const messageText = clientEmail;
        const phaseJSONString = JSON.stringify(generatePhaseJSON(originalPArray));
        const addMessage = httpsCallable(functions, 'addPhase');

        if (clientEmail !== selectedProgramInfoJson.clientEmail){
            //update clientEmail
            console.log("updating Client Email");
            await setDoc(doc(db, "trainers/" + userID + "/programs", selectedProgramID), {
                    clientEmail: clientEmail,
                    updated: serverTimestamp()
                }, {merge: true}
            );
            selectedProgramInfoJson.clientEmail = clientEmail;
            console.log("Client Email for Program " + selectedProgramID + ": " + selectedProgramInfoJson.name + " updated successfully");
        } else {
            console.log("clientEmail: ", clientEmail);
            console.log("selectedProgramInfoJson.clientEmail: ", selectedProgramInfoJson.clientEmail);
            console.log("client email is unchanged and already stored");
        }

        console.log("selectedProgramInfoJson: ", selectedProgramInfoJson);

        addMessage({
            email: messageText,
            info: deepCopy(selectedProgramInfoJson),
            totalPhases: programJson.p.length,
            phaseNumber: (selectedPhaseIndex + 1),
            phaseJSONString: phaseJSONString,
            programID: selectedProgramID
        })
            .then((result) => {
                // Read result of the Cloud Function.
                /** @type {any} */
                const data = result.data;
                console.log("data:");
                console.log(data);
                const sanitizedMessage = data.text;
                console.log("sanitizedMessage:");
                console.log(sanitizedMessage);
                hideLoader();
                showOkDialog("Phase " + (selectedPhaseIndex + 1) + " sent successfully to " + messageText);
            })
            .catch((error) => {
                // Getting the Error details.
                const code = error.code;
                const message = error.message;
                const details = error.details;
                console.log("error:");
                console.log(error);
                console.log("message:");
                console.log(error.message);
                showErrorDialog("" + error);
                hideLoader();
            });
    }

    function generatePhaseJSON(pArray){
        let originalPArray = deepCopy(pArray);
        //{"ph": ... }
        let thisPhaseJSON = originalPArray[selectedPhaseIndex];
        if (programJson["tp"] === globalProgramTypeTwoWeekSame) {
            const numberOfWeeksSections = thisPhaseJSON.ph.length;
            for (let weekIndex = 0; weekIndex < numberOfWeeksSections; weekIndex++){
                //index to duplicate will always be 2xWeekIndex because previous indices will be doubled
                const indexToDuplicate = weekIndex * 2;
                thisPhaseJSON.ph.splice(indexToDuplicate+1, 0, deepCopy(thisPhaseJSON.ph[weekIndex * 2]));
            }
        }
        return thisPhaseJSON;
    }

    function getMainTabDiv(){
        return (
            <>
                { showAddProgramModalFlag && <AddProgramModal setShowAddProgramModalFlag={setShowAddProgramModalFlag} showImportProgramDialog={showImportProgramDialog} showNewProgramDialog={showNewProgramDialog} /> }
                { showNewProgramModalFlag && <NewProgramModal setShowNewProgramModalFlag={setShowNewProgramModalFlag} addNewProgram={addNewProgram} />}
                { showDescriptionModalFlag && <DescriptionModal setShowDescriptionModalFlag={setShowDescriptionModalFlag} descriptionModalText={descriptionModalText} />}
                <div style={{display: "flex", justifyContent: "center", flexDirection: "column", paddingTop: "40px"}}>
                <div className="phases">
                    <div className={"phase-tabs" + ( (selectedMainTab === 0) ? " selected" : "")} onClick={() => setSelectedMainTab(0)}>{"Programs"}</div>
                    <div className={"phase-tabs" + ( (selectedMainTab === 1) ? " selected" : "")} onClick={() => setSelectedMainTab(1)}>{"Groups"}</div>
                </div>

                <div style={{display: "flex", justifyContent: "center"}}>
                    {selectedMainTab === 0 ?
                        <div>
                            <div style={{display: "flex", justifyContent: "flex-end"}}>
                                <div style={{display: "flex"}} className={"tooltip"}>
                                    <img src={addIcon} onClick={showAddProgramDialog} style={{width: "35px", height: "35px", cursor: "pointer"}} alt={"add icon"} />
                                    <span className="tooltiptext sets-cell-tooltiptext" style={{marginLeft: "-58px"}}>Add Program</span>
                                </div>
                            </div>
                            <div style={{display: "flex", flexDirection: "row", gap: "10px"}}>
                                {nameSearchText !== undefined && <input value={nameSearchText} onChange={e => setNameSearchText(e.target.value)} type="text" placeholder="Name" />}
                                {descriptionSearchText !== undefined && <input value={descriptionSearchText} onChange={e => setDescriptionSearchText(e.target.value)} type="text" placeholder="Description" />}
                            </div>
                            <table className="main-table">
                                <tbody>
                                <tr>
                                    <th className="table-header">S No.</th>
                                    <th className="table-header" style={{cursor: "pointer"}} onClick={() => setSortIDFor(sortID_Name_Ascending)}>Program Name</th>
                                    <th className="table-header" style={{cursor: "pointer"}} onClick={() => setSortIDFor(sortID_Description_Ascending)}>Description</th>
                                    <th className="table-header" style={{cursor: "pointer"}} onClick={() => setSortIDFor(sortID_UpdatedOn_Ascending)}>Updated On</th>
                                    <th className="table-header" style={{cursor: "pointer"}} onClick={() => setSortIDFor(sortID_ID_Ascending)}>ID</th>
                                    <th className="table-header">Action</th>
                                </tr>
                                {filteredPrograms.map((programInfo, index) => <ProgramsRow index={index} programInfo={programInfo} loadSelectedProgram={loadSelectedProgram} deleteProgram={deleteProgram} duplicateProgram={duplicateProgram} setShowDescriptionModalFlag={setShowDescriptionModalFlag} setDescriptionModaText={setDescriptionModalText} key={index} />) }
                                </tbody>
                            </table>
                        </div>
                        :
                        <>
                            <div>
                                <table className="main-table">
                                    <tbody>
                                    <tr>
                                        <th className="table-header">S No.</th>
                                        <th className="table-header">Class Name</th>
                                        <th className="table-header">Start Date</th>
                                        <th className="table-header">End Date</th>
                                        <th className="table-header">Class ID</th>
                                        <th className="table-header">Participants</th>
                                        <th className="table-header">Action</th>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </>
                    }
                </div>
            </div>
            </>
        )
    }

    function addExerciseButtonShouldBeShown(){
        try {
            return programJson.p[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].w?.length === 0 || !programJson.p[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].hasOwnProperty("w");
        } catch (e) {
            console.log("error in addExerciseButtonShouldBeShown: " + e);
            return true;
        }
    }

    function exercisesShouldBeShown(){
        try {
            return (exerciseMap.size && programJson.p[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].hasOwnProperty("w"));
        } catch (e) {
            console.log("error in exercisesShouldBeShown: " + e);
            return false;
        }
    }

    function getPhasesDropDown(){
        return (
            <div style={{display: "flex", alignItems: "center"}}>
                <div className={"modal-input-label"}>{"Phases :"}</div>
                <select className="exerciseName" style={{margin:"10px 0"}} value={programJson.p.length} onChange={e => setNumberOfPhases(e.target.value)}>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                </select>
            </div>
        )
    }

    function setNumberOfPhases(numOfPhases){

        let originalProgramJson = deepCopy({...programJson});
        let originalPArray = deepCopy([...programJson.p]);

        const currentNumberOfPhases = originalPArray.length;

        if (numOfPhases < currentNumberOfPhases){
            const phasesToRemove = currentNumberOfPhases - numOfPhases;
            originalPArray.splice(-phasesToRemove, phasesToRemove);
        } else if (numOfPhases > currentNumberOfPhases){

            //get blank week array containing as many days as in the current week
            let weekArray = {wk:[]};
            const currentNumberOfDays = originalPArray[selectedPhaseIndex].ph[selectedWeekIndex].wk.length;
            for (let i = 0; i < currentNumberOfDays; i++) {
                weekArray.wk.push({});
            }

            //get phase array containing as many weeks as in the current phase with each week a deep copy of the week created above
            let phaseArray = {ph: []};
            const currentNumberOfWeeks = originalPArray[selectedPhaseIndex].ph.length;
            for (let i = 0; i < currentNumberOfWeeks; i++) {
                phaseArray.ph.push(deepCopy(weekArray));
            }

            //append deep copy of the phase array created above as per number of phases
            const phasesToAdd = numOfPhases - currentNumberOfPhases;
            for (let i = 0; i < phasesToAdd; i++) {
                originalPArray.push(deepCopy(phaseArray));
            }
        }

        addToUndoStack(originalProgramJson);
        setProgramJson({...programJson, p: originalPArray});

        console.log(numOfPhases);
    }

    function getWeeksDropDown(){
        return (
            <div style={{display: "flex", alignItems: "center"}}>
                <div className={"modal-input-label"}>{"Phase " + (selectedPhaseIndex + 1) + ":"}</div>
                <select className="exerciseName" style={{margin:"10px 0"}} value={programJson.p[selectedPhaseIndex].ph.length} onChange={e => setNumberOfWeeks(e.target.value)}>
                    <option value="1">1 Week</option>
                    <option value="2">2 Weeks</option>
                    <option value="3">3 Weeks</option>
                    <option value="4">4 Weeks</option>
                    <option value="5">5 Weeks</option>
                    <option value="6">6 Weeks</option>
                    <option value="7">7 Weeks</option>
                    <option value="8">8 Weeks</option>
                    <option value="9">9 Weeks</option>
                    <option value="10">10 Weeks</option>
                </select>
            </div>
        )
    }

    function setNumberOfWeeks(numOfWeeks){

        let originalProgramJson = deepCopy({...programJson});
        let originalPArray = deepCopy([...programJson.p]);

        const currentNumberOfWeeks = originalPArray[selectedPhaseIndex].ph.length;

        if (numOfWeeks < currentNumberOfWeeks){
            const weeksToRemove = currentNumberOfWeeks - numOfWeeks;
            originalPArray[selectedPhaseIndex].ph.splice(-weeksToRemove, weeksToRemove);
        } else if (numOfWeeks > currentNumberOfWeeks){

            //get blank week array containing as many days as in the current week
            let weekArray = {wk:[]};
            const currentNumberOfDays = originalPArray[selectedPhaseIndex].ph[selectedWeekIndex].wk.length;
            for (let i = 0; i < currentNumberOfDays; i++) {
                weekArray.wk.push({});
            }

            //append deep copy of the week array created above as per number of weeks
            const weeksToAdd = numOfWeeks - currentNumberOfWeeks;
            for (let i = 0; i < weeksToAdd; i++) {
                originalPArray[selectedPhaseIndex].ph.push(deepCopy(weekArray));
            }
        }

        addToUndoStack(originalProgramJson);
        setProgramJson({...programJson, p: originalPArray});

        console.log(numOfWeeks);
    }

    function getDaysDropDown(){
        return (
            <div style={{display: "flex", alignItems: "center"}}>
                <div className={"modal-input-label"}>{"Week " + (selectedWeekIndex + 1) + ":"}</div>
                <select className="exerciseName" style={{margin:"10px 0"}} value={programJson.p[selectedPhaseIndex].ph[selectedWeekIndex].wk.length} onChange={e => setNumberOfDays(e.target.value)}>
                    <option value="1">1 Days</option>
                    <option value="2">2 Days</option>
                    <option value="3">3 Days</option>
                    <option value="4">4 Days</option>
                    <option value="5">5 Days</option>
                    <option value="6">6 Days</option>
                    <option value="7">7 Days</option>
                </select>
            </div>
        )
    }

    function setNumberOfDays(numOfDays){

        let originalProgramJson = deepCopy({...programJson});
        let originalPArray = deepCopy([...programJson.p]);

        const currentNumberOfDays = originalPArray[selectedPhaseIndex].ph[selectedWeekIndex].wk.length;

        if (numOfDays < currentNumberOfDays){
            const daysToRemove = currentNumberOfDays - numOfDays;
            originalPArray[selectedPhaseIndex].ph[selectedWeekIndex].wk.splice(-daysToRemove, daysToRemove);
        } else if (numOfDays > currentNumberOfDays){
            const daysToAdd = numOfDays - currentNumberOfDays;
            //push as many blank days as required
            for (let i = 0; i < daysToAdd; i++) {
                originalPArray[selectedPhaseIndex].ph[selectedWeekIndex].wk.push({});
            }
        }

        addToUndoStack(originalProgramJson);
        setProgramJson({...programJson, p: originalPArray});

        console.log(numOfDays);
    }

    return (
        <>
            <div className="main">
                <div style={{display: "flex", justifyContent: "flex-end", marginTop: "14px", marginRight: "14px", fontSize: "19px", gap: "14px"}}>
                    { (selectedProgramID !== globalNewProgramID) && <a onClick={goToHome} style={{cursor: "pointer"}}>Home</a>}
                    <a style={{cursor: "pointer"}}>Logout</a>
                    <a style={{cursor: "pointer"}}>{user.email}</a>
                </div>
                {selectedProgramID === globalNewProgramID ? getMainTabDiv() :
                    !programIsLoading &&
                    <>
                        {showImportDayModalFlag && <ImportDayModal setShowImportDayModalFlag={setShowImportDayModalFlag} importDayLink={importDayLink} setImportDayLink={setImportDayLink} startImportDay={startImportDay} /> }
                        {showProgramLinkImporterModalFlag && <ProgramLinkImporterModal setShowProgramLinkImporterModalFlag={setShowProgramLinkImporterModalFlag} programLinkImporterInputData={programLinkImporterInputData} setProgramLinkImporterInputData={setProgramLinkImporterInputData} startProgramImportViaLinks={startProgramImportViaLinks} />}
                        {showGeneratedPhaseLinkModalFLag && <GeneratedPhaseLinksModal selectedPhaseIndex={selectedPhaseIndex} setShowGeneratedPhaseLinkModalFlag={setShowGeneratedPhaseLinkModalFlag} generatedLinksStringData={generatedLinksStringData} />}
                        {showProgramNameEditModal && <ProgramNameEditModal programName={selectedProgramInfoJson?.name} setShowProgramNameEditModal={setShowProgramNameEditModal} updateProgramName={updateProgramName} />}
                        {showProgramDescriptionEditModal && <ProgramDescriptionEditModal programDescription={selectedProgramInfoJson?.desc} setShowProgramDescriptionEditModal={setShowProgramDescriptionEditModal} updateProgramDescription={updateProgramDescription} />}
                        {showAssignProgramToClientModalFlag && <AssignProgramToClientModal setShowAssignProgramToClientModalFlag={setShowAssignProgramToClientModalFlag} clientEmail={clientEmail} setClientEmail={setClientEmail} scanAndSendProgramToClient={scanAndSendProgramToClient} />}
                        <div className={"program-info"}>
                            <h2 style={{cursor: "pointer"}} onClick={showProgramNameEditDialog}>{selectedProgramInfoJson?.name}</h2>
                            {selectedProgramInfoJson?.desc ?
                                <h5 style={{cursor: "pointer"}} onClick={showProgramDescriptionEditDialog}><em>{selectedProgramInfoJson?.desc}</em></h5> :
                                <h6 style={{cursor: "pointer", fontSize: "18px", fontWeight: "normal"}} onClick={showProgramDescriptionEditDialog}><u>Add Description</u></h6>
                            }
                        </div>
                        <div className="phases">
                            {programJson.p?.map((phase, index) => <PhaseTabCell index={index} selected={index===selectedPhaseIndex} setSelectedPhaseIndex={setSelectedPhaseIndex} key={index} />)}
                        </div>
                        <div className="weeks">
                            {programJson.p && programJson.p[selectedPhaseIndex].ph.map((week, index) => <WeekTabCell index={index} programType={programJson.tp} selected={index===selectedWeekIndex} setSelectedWeekIndex={setSelectedWeekIndex} key={index} />)}
                        </div>
                        <div className="days-title-div">
                            <a>{getDayTitle()}</a>
                        </div>
                        <div style={{fontSize: "14px", textAlign: "center", fontWeight: "600", marginBottom: "10px"}}>
                            <a>{totalTimeString}</a>
                        </div>
                        <div className="days-workout-div">
                            <div className="days-column">
                                {programJson.p && programJson.p[selectedPhaseIndex].ph[selectedWeekIndex].wk.map((day, index) => <DayTabCell index={index} selected={index===selectedDayIndex} setSelectedDayIndex={setSelectedDayIndex} key={index} />) }
                            </div>
                            <div className="workout-column">
                                {addExerciseButtonShouldBeShown() && getAddExerciseButton()}
                                {exercisesShouldBeShown() && programJson.p[selectedPhaseIndex].ph[selectedWeekIndex].wk[selectedDayIndex].w?.map((sectionJSON, index) => <ExerciseSection sectionIndex={index} programJson={programJson} selectedPhaseIndex={selectedPhaseIndex} selectedWeekIndex={selectedWeekIndex} selectedDayIndex={selectedDayIndex} exerciseMap={exerciseMap} setTargetSection={setTargetSection} setTargetIndex={setTargetIndex} key={index} setProgramJson={setProgramJson} addToUndoStack={addToUndoStack} showErrorDialog={showErrorDialog}/>)}
                                <ExerciseSearch setProgramJson={setProgramJson} targetIndex={targetIndex} targetSection={targetSection} programJson={programJson} selectedPhaseIndex={selectedPhaseIndex} selectedWeekIndex={selectedWeekIndex} selectedDayIndex={selectedDayIndex} addToUndoStack={addToUndoStack} deepCopy={deepCopy} />
                            </div>
                            <div className="side-buttons-column">
                                {(programJson.tp === globalProgramTypeNormal) && getPhasesDropDown()}
                                {(programJson.tp === globalProgramTypeNormal) && getWeeksDropDown()}
                                {(programJson.tp === globalProgramTypeNormal) && getDaysDropDown()}
                                <div style={{display: "flex", gap: "5px"}}>
                                    <img src={undoIcon} onClick={loadFromUndoStack} style={{width: "35px", height: "35px", cursor: "pointer"}} alt={"add icon"} />
                                    <img src={redoIcon} onClick={loadFromRedoStack} style={{width: "35px", height: "35px", cursor: "pointer"}} alt={"add icon"} />
                                </div>
                                <button className="normal-button-black" style={{marginTop: "0"}} onClick={saveProgram}>Save</button>
                                <button className="normal-button-black" style={{marginTop: "0"}} onClick={generateLink}>Generate Link</button>
                                <button className="normal-button-black" style={{marginTop: "0"}} onClick={showImportDayDialog}>Import Day</button>
                                <button className="normal-button-black" style={{marginTop: "0"}} onClick={exportProgram}>Export Program</button>
                                <button className="normal-button-black" style={{marginTop: "0"}} onClick={showProgramLinkImporterDialog}>Import Program</button>
                                <button className="normal-button-black" style={{marginTop: "0"}} onClick={generateSelectedPhaseLinks}>{"Generate Phase" + (selectedPhaseIndex + 1) + " Links"}</button>
                                <button className="normal-button-black" style={{marginTop: "0"}} onClick={scanAndProgramForErrors}>Scan & Fix</button>
                                <button className="normal-button-black" style={{marginTop: "0"}} onClick={showAssignProgramToClientDialog}>{"Send Phase" + (selectedPhaseIndex + 1) + " to Client"}</button>
                                <button className="normal-button-black" style={{marginTop: "0"}} onClick={clearCurrentDay}>Clear Day</button>
                                {/*<button className="normal-button-black" style={{marginTop: "0"}} onClick={openProgramFile}>Import Program</button>*/}
                                <input type='file' id='file' ref={inputFile} style={{display: 'none'}} onChange={e=> loadLocalFile(e)}/>
                            </div>
                        </div>
                    </>
                }
            </div>
            {loading && <ModalSpinner message={loaderMessage}/>}
            { showOkModalFlag && <OkModal setShowOkModalFlag={setShowOkModalFlag} okModalMessage={okModalMessage} />}
            { showErrorModalFlag && <ErrorModal errorModalMessage={errorModalMessage} setShowErrorModalFlag={setShowErrorModalFlag} /> }
        </>
    );
}

export default Dashboard;