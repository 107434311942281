import UserLogin from "./pages/UserLogin";
import { initializeApp } from 'firebase/app';
import Dashboard from "./pages/Dashboard";
import {useState} from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {getFirestore} from "firebase/firestore";
import { getFunctions } from 'firebase/functions';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBk7-98IrW3PbfoH531mVhOh9GefrK9z-I",
    authDomain: "exerprise-3857d.firebaseapp.com",
    databaseURL: "https://exerprise-3857d.firebaseio.com",
    projectId: "exerprise-3857d",
    storageBucket: "exerprise-3857d.appspot.com",
    messagingSenderId: "216541425147",
    appId: "1:216541425147:web:6b37b5849f20fbd9342f5e",
    measurementId: "G-4M7HBQ48EM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const functions = getFunctions(app);

function App() {

    const [userSignedIn, setUserSignedIn] = useState(false);
    const [firebaseUser, setFirebaseUser] = useState("");

    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
        if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/auth.user
            // const uid = user.uid;
            setFirebaseUser(user);
            setUserSignedIn(true);
        } else {
            // User is signed out
            // ...
            setUserSignedIn(false);
        }
    });

    return (
        <>
            { userSignedIn ? <Dashboard db={db} userID={firebaseUser.uid} user={firebaseUser} functions={functions}/> : <UserLogin/> }
        </>
    )
}

export default App